import React, { Component } from "react";

export class FormUserDetails extends Component {
    continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
    
   


    render() {
      
    let nextButton;
        
    const { values, handleChange } = this.props;
    
     if (values.Client_Last_Name && values.Client_First_Name) {
       nextButton = (
         <button
           onClick={this.continue}
           class="text-dark group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
         >
           That's my name
           <svg
             xmlns="http://www.w3.org/2000/svg"
             class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor"
           >
             <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M17 8l4 4m0 0l-4 4m4-4H3"
             />
           </svg>
         </button>
       );
     }

     return (
       <div className="w-full">
         <h5 class="text-dark text-md">Let's start with your name?</h5>
         <div class="flex justify-center w-full">
           <div className="w-full">
             <div class="relative z-0 w-full mb-8 group">
               <input
                 type="text"
                 onChange={handleChange("Client_First_Name")}
                 defaultValue={values.Client_First_Name}
                 class="block pt-3 pb-1 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primaryfocus:outline-none focus:ring-0 focus:border-primary peer"
                 placeholder=" "
                 required
                 name="floating_first_name"
                 id="floating_first_name"
               />
               <label
                 for="floating_first_name"
                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:dark:text-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-primary"
               >
                 First name
               </label>
             </div>
             <div class="relative z-0 w-full mb-6 group">
               <input
                 type="text"
                 onChange={handleChange("Client_Last_Name")}
                 defaultValue={values.Client_Last_Name}
                 class="block pt-3  pb-1 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primaryfocus:outline-none focus:ring-0 focus:border-primary peer"
                 placeholder=" "
                 required
                 id="floatingPassword"
               />
               <label
                 for="floatingPassword"
                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:dark:text-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-primary"
               >
                 Last name
               </label>
             </div>
             <div class="flex space-x-2 justify-end ">{nextButton}</div>
           </div>
         </div>
       </div>
     );
  }
}

export default FormUserDetails;
