import React from "react";
import { Link } from "react-router-dom";
import payOnCard from "../../images/eccommerce/paying-withcard-online.jpg";
import { default as lineTop } from "../../images/assets/lineTop.svg";
import { default as lineBottom } from "../../images/assets/lineBottom.svg";

//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function EccommerceCTA() {
  return (
    <div>
      <div className="relative bg-white py-12">
        <section className="container relative z-10">
          <div className="lg:w-2/3 mx-8 md:mx-auto overflow-hidden shadow-lg rounded-xl bg-gradient-to-r from-primary to-primaryDark">
            <div className="grid grid-cols-12 gap-0 z-10">
              <div className="text-white lg:col-span-8 md:col-span-8 col-span-12 md:py-8 md:px-12 px-8 py-6">
                <h4>Boost Your Sales Today</h4>
                <p>
                  Looking for help to grow your business and increase your
                  sales? Contact our expert team and book a free consultation!{" "}
                </p>
                <Link to={"/Contact"}>
                  <button className="mt-4 mb-4 bg-white text-primary border border-white rounded-none px-4 py-2 hover:bg-primary hover:text-white">
                    Contact us now
                  </button>
                </Link>
              </div>
              <div
                className="lg:col-span-4 md:col-span-4 col-span-12 order-first md:order-last"
                style={{
                  minHeight: "200px",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${payOnCard})`,
                    position: "cover",
                    width: "100%",
                    height: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <img
          className="absolute top-0 left-0 topLineStyle hidden md:block"
          src={lineBottom}
        />
        <img
          className="absolute bottom-0 right-0 topLineStyle hidden md:block"
          style={{ bottom: "10%" }}
          src={lineTop}
        />
      </div>
    </div>
  );
}

export default EccommerceCTA;
