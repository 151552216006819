import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as rocket } from "../../images/assets/rocket.svg";
import { default as creatingBranding } from "../../images/assets/creatingBranding.svg";
import { default as brandingLogo } from "../../images/assets/brandingLogo.svg";
import { useSpring, animated, config, easings } from "react-spring";

export const BrandingImageBanner = (props) => {
  const smilingmanStyles = {
    bottom: "20px",
    right: "10px",
    width: "50%",
    position: "absolute",
  };

  const creatingBrandingStyles = {
    opacity: 1,
  };

  const { background, rotateZ } = useSpring({
    from: {
      //background: '#46e891',
      rotateZ: -10,
    },
    to: {
      //background: '#277ef4',
      rotateZ: 10,
    },
    config: {
      duration: 1500,
      easing: easings.easeInOutQuart,
    },
    loop: { reverse: true },
  });

  return (
    <div>
      <div className="relative bg-content">
        <section className="container mx-auto">
          <div className="">
            <section className="container relative mx-auto px-4 py-8 z-10 py-12">
              <div className="grid grid-cols-12 gap-8">
                <div className="lg:col-span-6 lg:col-span-6 col-span-12">
                  <div style={creatingBrandingStyles}>
                    <ScrollEffect speed={8}>
                      <div style={smilingmanStyles}>
                        <animated.div
                          style={{ background, borderRadius: 25, rotateZ }}
                        >
                          <img src={brandingLogo} />
                        </animated.div>
                      </div>

                      <img
                        className="w-100 p-2 mx-auto"
                        src={creatingBranding}
                      />
                    </ScrollEffect>
                  </div>
                </div>
                <div className="lg:col-span-6 col-span-12  relative py-12 md:py-12 lg:py-12">
                  <ScrollEffect speed={-3}>
                    <h4 class="text-lg mb-2 font-thin italic text-blue">
                      What is branding?
                    </h4>
                    <h4 class="text-4xl mb-5 text-dark">
                      You have a brand, you just don't{" "}
                      <span class="underlineSvg">know it</span>
                    </h4>
                    <p class="relative z-10">
                      Branding is not just your logo or your company colours; it
                      is how the public views your products and services. Tone
                      of voice, customer service and quality products are all
                      part of your brand. You could have Melbourne's best flat
                      white, but without a unified visual presence how will
                      everybody know?
                    </p>
                    <p class="relative z-10">
                      Designing your brand is a way of controlling public
                      perception of your business, this is achieved using
                      multiple design elements, such as a logo, typography and
                      colour theory. These visual elements enable you to
                      communicate your personality, without any direct
                      marketing. A good brand will present the best qualities of
                      your business. It is a short cut to positive opinion
                      within your chosen demographic.
                    </p>
                  </ScrollEffect>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};
