import React, { useState } from "react";
import {
  useSpring,
  animated,
  easings,
  config,
  molasses,
} from "@react-spring/web";

function AnimatedAsset(props) {
  const { x } = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: 9,
    },
    config: {
      duration: props.duration,
      tension: 300,
      friction: 10,
    },
    loop: true,
    reset: true,
  });

  return (
    <div>
      <animated.div
        style={{
          translateX: x.to({
            range: [0, 1, 2, 3, 4, 5, 6, 7, 9],
            output: [
              0,
              props.posx,
              0,
              props.minusx,
              0,
              props.posx,
              0,
              props.minusx,
              0,
            ],
          }),
          translateY: x.to({
            range: [0, 2, 6, 9],
            output: [0, props.posy, props.minusy, 0],
          }),
          rotate: x.to({
            range: [0, 6, 9],
            output: [props.rotate, props.minusrotate, props.rotate],
          }),
        }}
      >
        {props.children}
      </animated.div>
    </div>
  );
}
export default AnimatedAsset;
