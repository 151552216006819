import React, { useState } from "react";
import { useAxiosGet } from "./httpRequest";
import { Link } from "react-router-dom";
import { RevealText } from "./RevealText";
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';

function CategoryLoop(props) {
  const loop = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/categories`;

  const [categories, setCategory] = useState(false);

  let items = useAxiosGet(loop);

  let content = null;

  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(event);
  }

  if (items.error) {
    content = <div class="flex items-center justify-center space-x-2"></div>;
  }

  if (items.loading) {
    content = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  if (items.data) {
    content = (
      <div>
        <ul class="bg-white m-4 list-none w-full text-gray-900">
          <h3>Categories</h3>
          {items.data.map((item) => (
            <RevealText>
              <li className="hover:transition-all hover:translate-x-3 group w-full px-4 py-2">
                <Link
                  refresh="true"
                  className="text-dark group-hover:scale-150 transition transition-500"
                  onClick={() => {}}
                  to={`/posts/page/1/${item.id}`}
                >
                  {item.name}
                </Link>
              </li>
            </RevealText>
          ))}
        </ul>
      </div>
    );
  }

  return <div>{content}</div>;
}

export default CategoryLoop;
