import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/primary/computer.svg";
import { default as performancebg } from "../../images/performance/performanceintro.png";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function SEOIntro() {
  const optimisationBGStyle = {
    width: "80%",
  };

  return (
    <ChangeBGColour>
      <section className="container relative mx-auto px-4 py-24 z-10 md:py-12 lg:py-16 xl:py-28">
        <div className="grid grid-cols-12 gap-8">
          <div className="md:col-span-6 col-span-12  relative">
            <Reveal reveal={"h4"}>
              <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                Elevate Your Business with Expert Performance Marketing Services
              </h4>
            </Reveal>
          </div>

          <div class="md:col-span-6 col-span-12 z-10">
            <Reveal reveal={"body"}>
              <p class="relative z-10">
                Performance marketing is a dynamic approach to advertising where
                success is measured by specific actions or outcomes achieved.
                Unlike traditional marketing methods that focus on brand
                awareness, performance marketing emphasizes tangible results
                such as clicks, leads, sales, or other desired conversions. This
                form of marketing relies heavily on data analysis and
                optimization to drive efficient and measurable results. By
                targeting specific audiences and continuously refining
                strategies based on real-time metrics, performance marketing
                maximizes ROI and allows businesses to adapt quickly to changing
                market conditions.
              </p>
            </Reveal>
          </div>
        </div>
      </section>
      <section className="container relative mx-auto px-4 pb-24 z-10 md:pb-12 lg:pb-16 xl:pb-28">
        <div className="grid grid-cols-12 gap-8">
          <div class="md:col-span-6 col-span-12 z-10">
            <Reveal reveal={"body"}>
              <p class="relative z-10">
                Every business is distinct, and so should be its marketing
                strategy. At Plain Design Studio, we start by immersing
                ourselves in your brand, understanding its essence, audience,
                and aspirations. This deep dive enables us to tailor a bespoke
                performance marketing roadmap that aligns seamlessly with your
                goals.
              </p>
            </Reveal>
            <Reveal reveal={"body"}>
              <p class="relative z-10">
                Gone are the days of broadcasting your message to the masses and
                hoping for the best. Our targeted advertising solutions ensure
                that your message reaches the right audience, at the right time,
                through the right channels. Whether it's leveraging the power of
                search engine marketing, harnessing the reach of social media
                platforms, or strategically placing display ads, we ensure
                maximum impact and ROI.
              </p>
            </Reveal>
          </div>
          <div class="md:col-span-6 col-span-12 z-10">
            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0">
              <div className="relative">
                {/* <div style={searchStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="" src={search} />
                  </AnimatedAsset>
                </div> */}

                {/* <div style={googleStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={10}
                    minusx={-10}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={google} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div> */}
                {/* 
                <ScrollEffect speed={18} YFrom={8} YTo={-2}>
                  <Reveal>
                    <img
                      style={optimisationBGStyle}
                      className="p-2 mx-auto right-0 rounded-3xl overflow-hidden"
                      src={optimisationBG}
                    />
                  </Reveal>
                </ScrollEffect> */}
                <ScrollEffect speed={18} YFrom={-10} YTo={0}>
                  <div style={optimisationBGStyle}>
                    <Reveal>
                      <img className="" src={performancebg} />
                    </Reveal>
                  </div>
                </ScrollEffect>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ChangeBGColour>
  );
}

export default SEOIntro;
