import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated, config, easings } from "react-spring";
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
export const ChangeBGColour = (props) => {
  const hiddenRef = useRef();

  const [changeScrollColour, setChangeColour] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const scrollHandler = () => {
    if (
      window.pageYOffset + (window.innerHeight / 100) * 50 >=
      hiddenRef.current.offsetTop
    ) {
      setChangeColour(true);
    } else {
      setChangeColour(false);
    }
  };

  const { backgroundStyle } = useSpring({
    backgroundStyle: changeScrollColour ? 1 : 0,
    from: {
      backgroundStyle: 0,
    },
    config: { duration: 350 },
  });

  return (
    <div>
      <animated.div
        ref={hiddenRef}
        style={{
          backgroundColor: backgroundStyle.to({
            range: [0, 1],
            output: ["#ffffff", "#2B41EF"],
          }),
          color: backgroundStyle.to({
            range: [0, 1],
            output: ["black", "white"],
          }),
        }}
      >
        {props.children}
      </animated.div>
    </div>
  );
};
