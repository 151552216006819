import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as ranking } from "../../images/SEO/ranking.png";
import { default as optimisationBG } from "../../images/SEO/optimisationBG.png";
import { default as plus } from "../../images/SEO/plus.png";
import { default as google } from "../../images/SEO/googleLogo.png";
import { default as search } from "../../images/SEO/search.png";

//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function OptimisationSEO() {
  const plusStyle = {
    top: "5%",
    right: "0%",
    width: "150px",
    position: "absolute",
    zIndex: 2,
  };

  const searchStyle = {
    top: "10%",
    right: "20%",
    width: "100px",
    position: "absolute",
    zIndex: 2,
  };

  const googleStyle = {
    top: "5%",
    left: "20%",
    minWidth: "180px",
    width: "30%",
    position: "absolute",
    zIndex: 2,
  };

  const rankingStyle = {
    bottom: "40%",
    right: "5%",
    width: "200px",
    position: "absolute",
    zIndex: 2,
  };

  const optimisationBGStyle = {
    width: "100%",
    left: "0px",
  };

  return (
    <div>
      <div className="relative bg-white text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:pt-20 lg:pb-8">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0">
              <div className="relative">
                <div style={plusStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="" src={plus} />
                  </AnimatedAsset>
                </div>

                <div style={searchStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="" src={search} />
                  </AnimatedAsset>
                </div>

                <div style={rankingStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={ranking} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <div style={googleStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={10}
                    minusx={-10}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={google} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect speed={18} YFrom={-2} YTo={-10}>
                  <img
                    style={optimisationBGStyle}
                    className="p-2 mx-auto right-0"
                    src={optimisationBG}
                  />
                </ScrollEffect>
              </div>
            </div>
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <h3 class="leading-tight  font-heading relative z-10 mb-8">
                Secure payments through your Ecommerce store
              </h3>
              <p>
                There are billions of searches carried out on Google (and other
                search engines) every single day and the higher you rank on the
                search engine results pages (SERPS) the more people will visit
                your site.
              </p>
              <p class="mb-8 relative z-10">
                Websites that sit in the top 3 positions of page 1, receive 50%
                of all clicks - just think of how you search for things, how
                often do you scroll to the bottom of page one, let alone onto
                page 2 or beyond?!
              </p>
              <p class="mb-8 relative z-10">
                The main focus for your website should be offering content that
                is relevant to your industry and that matches what people are
                actually searching for. This means your content will fall into
                one of 4 categories; informational, transactional, commercial or
                navigational.
              </p>
              <p class="mb-8 relative z-10">
                Your content should be guided by the actual terms that people
                are actively searching for; called ‘search queries’.{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default OptimisationSEO;
