import React from "react";
import TopBanner from "../TopBanner";
import WebsiteIntro from "../views/Website/WebsiteIntro";
import WebsiteBody from "../views/Website/WebsiteBody";
import ServiceWebsite from "../views/Website/ServiceWebsite";
import WebsiteBranding from "../views/Website/WebsiteBranding";
import CarouselCases from "../views/CarouselCases";
import SpeedWebsite from "../views/Website/SpeedWebsite";

function Website() {
  return (
    <div>
      <TopBanner
        header="Website Design"
        metaDescription="We build SEO friendly code, which means it is easier to promote your website. SEO (Search Engine Optimisation) is often neglected by developers but it is an essential part of our design process. Our builds are fast which translates to high ranking pages on search engines such as Google. We also reduce third party plugins and JS files, so as not to bloat the site alongside both tagging and optimising any images used on the site. This process ensures Google will choose to display your website over templated, poorly executed sites, it’s a known fact nobody checks the second page!"
      />
      <WebsiteIntro />
      <ServiceWebsite />
      <WebsiteBody />
      <SpeedWebsite />
      <WebsiteBranding />
      <CarouselCases />
    </div>
  );
}

export default Website;
