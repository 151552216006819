import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as webPageShop } from "../../images/eccommerce/web-page-shop.png";
import { default as arrowCircle } from "../../images/eccommerce/arrows.png";
import { default as mobileShop } from "../../images/eccommerce/mobile-shop.png";
import { default as starRating } from "../../images/eccommerce/star-rating.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function CreateEccommerce() {
  const iconStyles = {
    left: "0%",
    width: "5%",
    maxWidth: "350px",
    minWidth: "280px",
    top: "0%",
    opacity: "0.05",
    position: "absolute",
  };

  const arrowStyle = {
    bottom: "10%",
    left: "20px",
    width: "120px",
    position: "absolute",
    zIndex: 2,
  };

  const webPageStyle = {
    bottom: "30%",
    width: "100%",
    minWidth: "220px",
    height: "auto",
    left: "-100%",
    position: "absolute",
  };

  const ratingStyle = {
    bottom: "35%",
    left: "20px",
    width: "220px",
    position: "absolute",
    zIndex: 2,
  };

  const mobileShopStyle = {
    bottom: "0px",
    right: "5%",
    width: "30%",
    minWidth: "220px",
    position: "absolute",
  };

  return (
    <div>
      <div className="relative bg-content text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0 lg:px-8">
              <div className="relative">
                <div style={arrowStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={0}
                    minusrotate={500}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img src={arrowCircle} />
                  </AnimatedAsset>
                </div>

                <div style={ratingStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect XFrom={20} XTo={-20} YFrom={20} YTo={45}>
                      <img src={starRating} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect YFrom={-8} YTo={8}>
                  <img
                    className="w-100 p-2 mx-auto right-0"
                    src={webPageShop}
                  />
                </ScrollEffect>

                <div style={mobileShopStyle}>
                  <ScrollEffect YFrom={0} YTo={-28}>
                    <img
                      className="w-100 p-2 mx-auto right-0"
                      src={mobileShop}
                    />
                  </ScrollEffect>
                </div>

                <div className="absolute" style={{ ...webPageStyle }}></div>
              </div>
            </div>

            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <h3 class="leading-tight  font-heading relative z-10 mb-8">
                Create your Ecommerce store and sell online now
              </h3>
              <p>
                Creating a great online shopping experience doesn’t have to be
                hard, our team is experienced in delivering beautifully designed
                online stores combined with simple functionality which will
                leave your customers happy and coming back for more.
              </p>
              <p class="mb-8 relative z-10">
                We’ll{" "}
                <Link to={`/services/website-design`} className="">
                  create a site
                </Link>{" "}
                that is easy for you to take orders, add new products, manage
                your stock and communicate with your customers as and when you
                need to.
              </p>

              <p class="mb-8 relative z-10">
                After a consultation with our Ecommerce team, we’ll{" "}
                <Link
                  to={`/services/content-marketing-graphic-design`}
                  className=""
                >
                  design and build
                </Link>{" "}
                the perfect platform for your business, whether you’re selling
                just a handful of bespoke gifts or thousands of units every day.{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CreateEccommerce;
