import React from "react";
//import TopBanner from "../TopBanner";
import TopBanner from "../views/Performance/TopBanner";
import SEOIntro from "../views/Performance/SEOIntro";
import DataDriven from "../views/Performance/DataDriven";
import SEOBusiness from "../views/Performance/SEOBusiness";
import SEOCTA from "../views/Performance/SEOCTA";
import SEOOnPage from "../views/Performance/SEOOnPage";
import ContentWriting from "../views/Performance/ContentWriting";
import FAQDropdown from "../../Hooks/FAQDropdown";
import { RevealImage } from "../../Hooks/RevealImage";
import { Link } from "react-router-dom";

function PerformanceMarketing() {
  return (
    <div>
      <TopBanner />
      <SEOIntro />
      <DataDriven />
      <div className="container py-8">
        <div className="lg:w-8/12 mx-auto">
          <RevealImage>
            <h3 class="text-center">How we optimise performance</h3>
          </RevealImage>
          <FAQDropdown title={"Continuous Monitoring and Analysis"}>
            <p>
              Our journey towards optimization begins with meticulous monitoring
              of key performance metrics across all facets of your campaigns.
              From click-through rates and conversion rates to bounce rates and
              engagement metrics, we leave no stone unturned in our quest for
              insights.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"Real-Time Adaptation"}>
            <p>
              In the fast-paced world of digital marketing, agility is
              paramount. Armed with real-time data, our team is empowered to
              make swift, informed decisions to optimize your campaigns on the
              fly. Whether it's adjusting bid strategies, refining audience
              targeting, or tweaking ad creatives, we stay one step ahead of the
              competition by staying responsive to evolving trends and user
              behavior. This is closely linked to{" "}
              <Link
                alt="View search engine optimisation service"
                to="/services/SEO-search-engine-optimisation"
              >
                search engine optimisation
              </Link>
            </p>
          </FAQDropdown>

          <FAQDropdown title={"A/B Testing and Experimentation"}>
            <p>
              We're firm believers in the power of experimentation. Through
              rigorous A/B testing and multivariate testing, we systematically
              evaluate different elements of your campaigns to identify winning
              variations that drive superior results. From headline variations
              to call-to-action buttons, every aspect of your campaign undergoes
              scrutiny to uncover optimization opportunities.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"Audience Insights and Segmentation"}>
            <p>
              Understanding your audience is paramount to delivering
              personalized and compelling marketing experiences. By analyzing
              demographic data, behavioral patterns, and user preferences, we
              gain invaluable insights into what makes your audience tick. Armed
              with this knowledge, we segment your audience into distinct
              cohorts, allowing us to tailor messaging and offers that resonate
              on a deeper level.
            </p>
          </FAQDropdown>

          <FAQDropdown title={"Predictive Analytics and Forecasting"}>
            <p>
              Anticipation is key to staying ahead of the curve. Leveraging the
              power of predictive analytics, we forecast future trends and
              performance outcomes based on historical data and market dynamics.
              This proactive approach enables us to preemptively adjust our
              strategies and capitalize on emerging opportunities before they
              materialize.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"Iterative Refinement"}>
            <p>
              Optimization is not a one-time endeavor; it's an ongoing journey
              of refinement and improvement. Through iterative testing and
              refinement cycles, we continuously fine-tune your campaigns to
              extract maximum value and ROI. Whether it's optimizing landing
              page experiences, streamlining conversion funnels, or adjusting
              targeting parameters, we leave no stone unturned in our pursuit of
              excellence. Performance marketing will utilise{""}
              <Link to="/services/website-design">Website design</Link>
            </p>
          </FAQDropdown>
        </div>
      </div>
    </div>
  );
}

export default PerformanceMarketing;
