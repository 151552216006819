import React from "react";
import { Link } from "react-router-dom";
import payOnCard from "../../images/eccommerce/paying-withcard-online.jpg";
import { default as thumbs } from "../../images/assets/thumbs.svg";

//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";

const thumbsStyles = {
  width: "150px",
  left: "0px",
  bottom: "0px",
  marginRight: "50%",
};

function SEOCTA() {
  return (
    <div>
      <div className="relative bg-light py-8">
        <div
          className="bg-primary h-50 w-100 absolute"
          style={{ width: "100%", bottom: "0px", height: "50%" }}
        ></div>
        <section className="container relative z-10">
          <div className="lg:w-2/3 mx-8 md:mx-auto overflow-hidden shadow-lg rounded-xl bg-white">
            <div className="grid grid-cols-12 gap-0 z-10">
              <div className="text-dark lg:col-span-8 md:col-span-8 col-span-12 md:py-8 md:px-12 px-8 py-6">
                <h4>Boost Your Sales Today</h4>
                <p>
                  Looking for help to grow your business and increase your
                  sales? Contact our expert team and book a free consultation!{" "}
                </p>
                <Link to={"/Contact"}>
                  <button className="mt-4 mb-4 bg-primary text-white border border-white rounded-none px-4 py-2 hover:bg-primary hover:text-white">
                    Contact us now
                  </button>
                </Link>
              </div>
              <div
                className="lg:col-span-4 md:col-span-4 col-span-12 order-first md:order-last"
                style={{
                  minHeight: "200px",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${payOnCard})`,
                    position: "cover",
                    width: "100%",
                    height: "100%",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        <div style={thumbsStyles} className="absolute z-8">
          <ScrollEffect speed={3} XFrom={-5} XTo={10}>
            <AnimatedAsset
              duration={12300}
              rotate={15}
              minusrotate={-25}
              posx={-5}
              minusx={8}
              posy={5}
              minusy={-5}
            >
              <img alt="web development computer image" src={thumbs} />
            </AnimatedAsset>
          </ScrollEffect>
        </div>
      </div>
    </div>
  );
}

export default SEOCTA;
