import React from "react";
import TopBanner from "../TopBanner";
import ServicesBranding from "../views/Services/ServicesBranding";
import ServicesGraphicDesign from "../views/Services/ServicesGraphicDesign";
import ServicesWebDesign from "../views/Services/ServicesWebDesign";
import FAQDropdown from "../../Hooks/FAQDropdown";
import { Link } from "react-router-dom";
import { RevealImage } from "../../Hooks/RevealImage";

function Services() {
  return (
    <div>
      <TopBanner
        metaHeader="Digital marketing services provided by Plain Design Studio"
        header="Services"
        topHeader="Digital marketing services"
        metaDescription="Learn more about Plain Design Studio digital marketing services in Melbourne, Victoria. We are website and digital marketing professionals"
      />

      <ServicesWebDesign />
      <ServicesBranding />
      <ServicesGraphicDesign />

      <div className="container py-8">
        <div className="lg:w-8/12 mx-auto">
          <RevealImage>
            <h3 class="text-center">More Services</h3>
          </RevealImage>
          <FAQDropdown title={"SEO for your website"}>
            <p>
              If you’re looking for more people to know about your business and
              how your products or services can help them, SEO could be perfect
              for you.
            </p>
            <p>
              There are so many reasons why
              <Link
                alt="View search engine optimisation service"
                to="/services/SEO-search-engine-optimisation"
              >
                SEO (search engine optimisation)
              </Link>
              is important for your business. Not only will it help build
              awareness of your brand and what you have to offer, it will build
              trust between you and your potential clients and help position you
              as a thought leader within your industry.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"Ecommerce websites"}>
            <p>
              The rise of Ecommerce websites shows no sign of slowing down and
              selling your products and services online has never been easier.
            </p>
            <p>
              Creating a great online shopping experience doesn’t have to be
              hard, our team is experienced in delivering beautifully designed
              online stores combined with simple functionality which will leave
              your customers happy and coming back for more.
            </p>
            <Link
              alt="View search engine optimisation service"
              to="/services/SEO-search-engine-optimisation"
            >
              find out more about our ecommerce website
            </Link>
          </FAQDropdown>

          <FAQDropdown title={"TikTok marketing"}>
            <p>
              As a leading marketing agency, we recognize TikTok as a powerhouse
              platform for engaging with audiences in innovative ways.
              Leveraging TikTok's dynamic features such as short-form videos,
              trends, challenges, and influencer partnerships, we craft
              compelling campaigns that resonate with our clients' target
              demographics.
            </p>
            <p>
              Our approach blends creativity with data-driven insights to ensure
              maximum impact and ROI. With TikTok's vast user base and virality
              potential, our agency excels in creating authentic and memorable
              brand experiences that drive awareness, engagement, and
              ultimately, conversions. Whether it's launching a new product,
              amplifying brand messaging, or connecting with Gen Z and
              Millennials, TikTok marketing offers boundless opportunities for
              brands to stand out and thrive in the digital landscape.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"Performance marketing"}>
            <p>
              At our marketing agency, performance marketing is at the core of
              our strategy, driving measurable results and tangible growth for
              our clients. By meticulously analyzing data and leveraging a range
              of channels including PPC advertising, affiliate marketing, and
              email campaigns, we ensure every marketing dollar is optimized for
              maximum ROI. Our approach is agile and data-driven, constantly
              refining our tactics based on real-time analytics to adapt to
              shifting market trends and consumer behaviors. From increasing
              website traffic and lead generation to boosting conversions and
              revenue, our performance marketing expertise empowers brands to
              achieve their business objectives efficiently and effectively in
              today's competitive landscape.
            </p>
          </FAQDropdown>

          <FAQDropdown title={"Social network marketing"}>
            <p>
              As a forward-thinking marketing agency, we understand the power of
              social network marketing in building connections and driving
              growth. Leveraging platforms like Facebook, Instagram, Twitter,
              and LinkedIn, we craft strategies tailored to resonate with our
              clients' target audiences. Our approach focuses on authenticity
              and relevance, creating content that sparks conversations and
              fosters community engagement.
            </p>
            <p>
              From content creation to influencer partnerships, our expertise
              ensures our clients stand out online. We use data analytics to
              refine tactics, optimizing campaigns for maximum impact and ROI.
              Staying agile, we adapt to trends and behaviors, keeping our
              clients ahead in social media marketing.
            </p>
            <p>
              Through social network marketing, we help brands foster loyalty,
              increase awareness, and drive conversions. By building authentic
              relationships and delivering valuable content, we empower brands
              to connect deeply with consumers and inspire action. In today's
              digital landscape, our agency's expertise in social network
              marketing drives success and long-term growth for our clients.
            </p>
          </FAQDropdown>
          <FAQDropdown title={"App development"}>
            <p>
              At Plain Design Studio, we offer top-tier app development services
              designed to bring our clients' visions to life. Our expert team
              collaborates closely with clients to understand their unique
              needs, ensuring that every app we create is tailored precisely to
              their requirements. From brainstorming concepts to designing sleek
              user interfaces and implementing robust backend functionalities,
              we guide our clients through every step of the development
              process. With a keen eye for detail and a commitment to
              excellence, we deliver cutting-edge mobile applications that not
              only meet but exceed expectations. At Plain Design Studio, we
              don't just build apps; we craft immersive digital experiences that
              leave a lasting impression on users and drive business success.
            </p>
          </FAQDropdown>
        </div>
      </div>
    </div>
  );
}

export default Services;
