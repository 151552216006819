import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/primary/thumbs.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";

function GraphicsBody() {
  return (
    <div>
      <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
        <div className="grid grid-cols-12 gap-8">
          <div className="md:col-span-5 col-span-12  relative">
            <Reveal reveal={"h4"}>
              <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                <span className="toplinesSvg">Boost</span> customer engagement
                with tailored marketing content
              </h4>
            </Reveal>

            <div className="grid grid-cols-12 absolute gap-8 top-0">
              <div className="col-span-12">
                <ScrollEffect
                  className=""
                  speed={-10}
                  rotateFrom={30}
                  rotateTo={-15}
                >
                  <img className="w-100 opacity-10" src={thumb} />
                </ScrollEffect>
              </div>
            </div>
          </div>

          <div class="md:col-span-7 col-span-12 z-10">
            <p class="mb-8 relative z-10">
              Our work begins way before we roll out any design content. We will
              guide you in laying out clear goals with a focus on tangible
              results.
            </p>
            <p class="mb-8 relative z-10">
              After we have identified your target audience, it’s time for us to
              focus on tailoring the presentation of your product or service as
              the answer to your customers needs.
            </p>
            <p class="mb-8 relative z-10">
              The  benefit of producing a focused plan in a marketing
              campaign is you are able to check progress against your set goals
              and adjust as needed on the way to success!
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GraphicsBody;
