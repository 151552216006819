import { React } from "react";
import { Reveal } from "../../../Hooks/Reveal";

function Overview(props) {
  //const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${props.postId}`;

  //const post = useAxiosGet(url);

  return (
    <div>
      <div className="bg-dark">
        <div class="container text-light p-5 md:py-16 sm:py-12">
          <div class="grid grid-cols-12 gap-4 mb-8">
            <div class="col-span-12 md:col-span-4 ">
              <Reveal>
                <h3 className="md:text-center">Overview</h3>
              </Reveal>
            </div>

            <div class="col-span-12 md:col-span-8 mx-auto ">
              <Reveal>
                <p>{props.acf.overview}</p>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Overview;
