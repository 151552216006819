import React from "react";
import { Link } from "react-router-dom";
import { default as wooCommerce } from "../../images/logo/images/wooCommerce.svg";
import { default as shopify } from "../../images/logo/images/shopify.svg";

function EccommercePlatform() {
  return (
    <div>
      <div className="relative bg-light text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="col-span-12 lg:col-span-8 lg:col-start-3 text-center">
              <h3 class="text-3xl leading-tight text-center font-heading relative z-10 mb-2 ">
                <span className="toplinesSvg">Which</span> Ecommerce platform
                <span className="text-primary"> should I choose?</span>
              </h3>
              <p class="text-center text-lg relative z-10">
                Think all Ecommerce stores run the same way? Think again!
              </p>
              <p class="mb-8 relative z-10">
                Choosing the right platform for your online business is a factor
                in whether it will be successful. Can it scale with you as your
                business grows? Can it easily integrate with your digital
                marketing? How easy is it to upload products, customise and
                manage stock?
              </p>
              <p class="mb-8 relative z-10">
                Do you even need it to do all of these things? We find that most
                of our clients use one of 2 platforms:
              </p>
            </div>
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 p-8 lg:p-12 bg-white rounded-xl">
              <img
                src={wooCommerce}
                className="mb-8"
                style={{ maxheight: "80px", height: "60px" }}
              />
              <h5 class="leading-tight text-lg font-heading relative z-10 mb-4">
                Woocommerce
              </h5>
              <p>
                Powering 99% of Ecommerce sites built on WordPress, WooCommerce
                offers unlimited customisation as well as{" "}
                <Link
                  to={`/services/SEO-search-engine-optimisation`}
                  className=""
                >
                  great SEO options
                </Link>{" "}
                <Link to={`/services/website-design`} className="">
                  {" "}
                  to help raise the profile{" "}
                </Link>{" "}
                of your online store.
              </p>
              <p class="mb-8 relative z-10">
                It can also be used to sell anything, anywhere. You might not
                have a physical product to sell, but a service. If you already
                have a WordPress website to promote your services (or products)
                and want to take the leap into the online retail world, then
                adding WooCommerce is simple and affordable without the need to
                migrate to a different platform altogether,
              </p>
            </div>

            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 p-8 lg:p-12 bg-white rounded-xl">
              <img
                src={shopify}
                className="mb-8"
                style={{ maxheight: "80px", height: "60px" }}
              />
              <h5 class="leading-tight text-lg font-heading relative z-10 mb-4">
                Shopify
              </h5>
              <p>
                Shopify offers an Ecommerce platform suitable from small
                independents to multi-national retailers with a tiered pricing
                structure and hundreds of apps making it an easy and affordable
                option for many.
              </p>

              <p class="mb-8 relative z-10">
                Sure, you can use one of their many templates and get yourself
                up and running, but for a truly eye-catching online shop, our
                designers will create a bespoke store that boasts all the
                functionality and ease of use that you might need.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EccommercePlatform;
