import React from "react";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as cat } from "../../images/assets/primary/thumbs.svg";
import { default as creatingBranding } from "../../images/assets/creatingBranding.svg";
import { default as brandingLogo } from "../../images/assets/brandingLogo.svg";
import { useSpring, animated, config, easings } from "react-spring";
import { RevealText } from "../../../Hooks/RevealText";
import { RevealImage } from "../../../Hooks/RevealImage";

function HomeBranding() {
  const smilingmanStyles = {
    bottom: "20px",
    right: "10px",
    width: "50%",
    position: "absolute",
  };

  const iconStyles = {
    left: "40%",
    width: "25%",
    maxWidth: "250px",
    minWidth: "180px",
    top: "0%",
    position: "absolute",
    opacity: "0.05",
  };

  const creatingBrandingStyles = {
    opacity: 1,
  };

  const { background, rotateZ } = useSpring({
    from: {
      background: "#fff",
      rotateZ: -18,
    },
    to: {
      background: "#fff",
      rotateZ: 18,
    },
    config: {
      duration: 1200,
      easing: easings.easeInOutQuart,
    },
    loop: { reverse: true },
  });

  return (
    <div>
      <div className="col-span-12 relative px-8">
        <div style={creatingBrandingStyles} style={{ height: "250px" }}>
          <ScrollEffect speed={8}>
            <div style={smilingmanStyles}>
              <animated.div style={{ background, borderRadius: 25, rotateZ }}>
                <img src={brandingLogo} />
              </animated.div>
            </div>

            <img className="w-100 p-2 mx-auto" src={creatingBranding} />
          </ScrollEffect>
        </div>
      </div>

      <div class="content-center bg-light rounded-xl py-12 px-6 relative z-10">
        <RevealText>
          <h5 class="leading-tight font-heading relative z-10 mb-8 text-lg">
            Comprehensive Graphic Design and Branding Services
          </h5>
        </RevealText>
        <RevealText delay="0.1">
          <h6 class="text-primary mb-2">Brand Identity Design</h6>
          <p>
            Crafting memorable logos and visual identities that resonate with
            your audience.
          </p>
        </RevealText>
        <RevealText delay="0.2">
          <h6 class="text-primary mb-2">Website Design</h6>
          <p>
            Creating user-friendly and visually stunning websites that enhance
            user experience and drive conversions.
          </p>
        </RevealText>
        <Link to="/services"></Link>
      </div>
    </div>
  );
}

export default HomeBranding;
