import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { default as coffee } from "../../images/assets/primary/coffee.svg";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { RevealText } from "../../../Hooks/RevealText";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";

export default function HomeIntro() {
  const stickerImage = {
    right: "0px",
    top: "-100px",
    maxWidth: "200px",
    width: "35%",
    margin: "0px",
    position: "absolute",
  };

  /*const corossImage = {
        left: '-50px',
        top: '-50px'
    };
    
    const rotate = useParallax({
        rotate: [-0, 140],
        speed: 4,
    });*/
  // const slidetop = {
  //     top: '20px'
  // }

  return (
    <ChangeToPrimary
      className="bg-dark"
      colourFrom={"bgPrimary"}
      colourTo={"bgSecondary"}
    >
      <div className="relative  min-h-screen content-center py-16 md:py-24 lg:py-40">
        <section className="container relative mx-auto z-10 px-4">
          {/* <div style={stickerImage}>
            <ScrollEffect
              className=" w-100"
              speed={10}
              rotateFrom={30}
              rotateTo={10}
            >
              <AnimatedAsset
                duration={12300}
                rotate={5}
                minusrotate={-5}
                posx={0}
                minusx={0}
                posy={0}
                minusy={0}
              >
                <img alt="Thumb illustration" src={coffee} />
              </AnimatedAsset>
            </ScrollEffect>
          </div> */}
          <div className="grid grid-cols-12 gap-2">
            <div className="md:col-span-5 col-span-12  relative">
              <RevealText delay="0.5">
                <h2 class=" text-xl leading-tight md:text-xl lg:text-1xl lg:text-3xl font-heading relative z-10">
                  Welcome to Plain Design Studio Leading Design and Marketing
                  Experts
                </h2>
              </RevealText>

              {/* <div className="w-100 absolute">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img
                      alt="Thumb illustration"
                      className="w-100 opacity-10"
                      src={thumb}
                    />
                  </ScrollEffect>
                </div>
              </div> */}
            </div>
            <div class="md:col-span-7 col-span-12 z-10">
              <RevealText>
                <p class="mb-8 relative z-10">
                  At Plain Design Studio, we’re all about turning branding, web
                  design, graphic design, social media campaigns, and SEO into
                  something truly unforgettable! Think of us as the secret sauce
                  that makes your brand shine and your marketing campaigns pop.
                  From crafting standout brand identities to designing
                  jaw-dropping websites, we’re here to make your business the
                  talk of Melbourne.
                </p>
              </RevealText>
              <RevealText>
                <p class="mb-8 relative z-10">
                  As the fresh faces on the scene, we’re excited to shake things
                  up with our innovative ideas and creative flair. We’re here to
                  ensure your brand not only stands out but leaves a lasting
                  impression. Get ready for a marketing shake-up like no other!
                </p>
              </RevealText>
              <RevealText>
                <p class="mb-8 relative z-10">
                  Founded with a passion for design excellence and strategic
                  marketing, Plain Design Studio has quickly become a leading
                  agency known for delivering outstanding results. Our mission
                  is simple: to create designs that look amazing and drive real
                  business success.
                </p>
              </RevealText>
              <RevealText>
                <Link to="/about" className="">
                  <button
                    alt="About Plain Design Studio"
                    class="mt-5 group inline-block text-base leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  >
                    About us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 w-10 ml-2 group-hover:translate-x-8 transition transition-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </Link>
              </RevealText>
            </div>
          </div>
        </section>
      </div>
    </ChangeToPrimary>
  );
}
