import React from 'react'
import { default as logo } from './images/logo/logo.svg'
import { useSpring, animated } from '@react-spring/web'
import { useState } from 'react'
import RollingBanner from "./RollingBanner";
import Iconhover from "../Hooks/Iconhover";

function Footer() {
  // const [facebook, facebooktoggle] = useState(true)
  // const { x } = useSpring({
  //   from: {
  //     x: facebook ? 0 : 1,

  //   },
  //   x: facebook ? 1 : 0,
  //   config: { duration: 380,    tension: 300,
  //     friction: 10,
  //   },
  // })

  /*
  const [twitter, twittertoggle] = useState(true)
  const { y } = useSpring({
    from: {
      y: twitter ? 0 : 1,
      
    },
    y: twitter ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })

  const [insta, instatoggle] = useState(true)
  const { z } = useSpring({
    from: {
      z: insta ? 0 : 1,
      
    },
    z: insta ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })

  const [linkedin, linkedintoggle] = useState(true)
  const { a } = useSpring({
    from: {
      a: linkedin ? 0 : 1,
      
    },
    a: linkedin ? 1 : 0,
    config: { duration: 380,    tension: 300,
      friction: 10,
    },
  })
  */

  return (
    <div>
      <RollingBanner props="gradient.false" />
      <hr class="text-primary" />
      <footer className="relative text-center lg:text-left bg-light">
        <div class="flex justify-center items-center lg:justify-center p-6 bg-white">
          <div class="flex justify-center text-secondary">
            <a href="https://www.facebook.com/plaindesignliverpool">
              <Iconhover class="mr-6 flex justify-center p-2 bg-white rounded-circle rounded-full">
                <svg
                  aria-hidden="true"
                  style={{ width: "15px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  class="w-90"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    class="fill-primary"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path>
                </svg>
              </Iconhover>
            </a>

            <a href="https://www.instagram.com/plaindesignstudio/">
              <Iconhover class="mr-6 flex justify-center p-2 bg-white rounded-circle rounded-full">
                <svg
                  aria-hidden="true"
                  style={{ width: "25px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram"
                  class="w-100"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    class="fill-primary"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </Iconhover>
            </a>

            <a href="https://www.linkedin.com/company/plain-design-studio/">
              <Iconhover class="mr-6 flex justify-center p-2 bg-white rounded-circle hover:top-10 rounded-full">
                <svg
                  aria-hidden="true"
                  style={{ width: "25px" }}
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin-in"
                  class="w-90"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    class="fill-primary"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </Iconhover>
            </a>
          </div>
        </div>
        <div class="mx-6 py-10 text-center md:text-left text-dark ">
          <div class="grid grid-1 lg:grid-cols-12 pt-12 pb-12 gap-8">
            <div className=" col-span-4  flex justify-center">
              <div
                style={{ width: "150px", height: "150px" }}
                className="rounded-full bg-primary flex justify-center md:justify-start"
              >
                <img src={logo} width="150px" height="150px"></img>
              </div>
            </div>
            <div class="col-span-4 mx-auto justify-center md:justify-start">
              <h5
                class="
           
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          "
              >
                Plain Design Studio
              </h5>
              <p>
                We are always striving to create beauty within functionality.
                Specialising in branding, web design, graphic design, social
                media campaigns and SEO
              </p>
            </div>

            <div class="col-span-4 mx-auto">
              <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                Contact
              </h6>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
                <a href="mailto:admin@plaindesignstudio.com">
                  admin@plaindesignstudio.com
                </a>
              </p>
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="phone"
                  class="w-4 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                  ></path>
                </svg>
                <a href="Tel:0405-78-28-12">0405 78 28 12</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer