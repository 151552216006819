import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as Coin } from "../../images/assets/coin-red.svg";
import { default as webPageShop } from "../../images/eccommerce/eccommercePhone.png";
import { default as cardPayment } from "../../images/eccommerce/cardPayment.png";
import { default as mobileShop } from "../../images/eccommerce/mobile-shop.png";
import { default as starRating } from "../../images/eccommerce/star-rating.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function PaymentsEccommerce() {
  const cardPaymentStyle = {
    top: "25%",
    right: "0%",
    width: "50%",
    position: "absolute",
    zIndex: 2,
  };

  const coinStyle = {
    bottom: "10%",
    right: "20%",
    width: "120px",
    position: "absolute",
    zIndex: 2,
  };

  const mobilePaymentStyle = {
    width: "100%",
    left: "0px",
    marginRight: "50%",
  };

  return (
    <div>
      <div className="relative bg-light text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:pt-20 lg:pb-8">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="lg:col-span-6 md:col-span-12 md:col-start-2 col-span-12">
              <h3 class="leading-tight  font-heading relative z-10 mb-8">
                Secure payments through your Ecommerce store
              </h3>
              <p>
                When it comes to selling online, your customers need to have the
                reassurance that their contact information and payment details
                are secure.
              </p>
              <p class="mb-8 relative z-10">
                We’ll match you up with the best online payment partner for your
                business and ensure the payment process is integrated seamlessly
                to your site.
              </p>
              <p class="mb-8 relative z-10">
                This will not only provide a better buying and checking out
                process but will also give you the full knowledge that your
                customers’ data and transactions will be handled securely.
              </p>
            </div>

            <div className="lg:col-span-6 md:col-span-9 md:col-start-2 col-span-12 lg:mt-0 order-first lg:order-last">
              <div className="relative">
                <div style={cardPaymentStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="shadow-lg" src={cardPayment} />
                  </AnimatedAsset>
                </div>

                <div style={coinStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={Coin} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect speed={8}>
                  <img
                    style={mobilePaymentStyle}
                    className="p-2 mx-auto right-0"
                    src={webPageShop}
                  />
                </ScrollEffect>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PaymentsEccommerce;
