import React from "react";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as computer } from "../../images/assets/primary/cat.svg";
import { default as creatingBranding } from "../../images/assets/graphic-design-board.jpg";
import { default as brandingLogo } from "../../images/assets/graphic-design-tool.png";
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { RevealText } from "../../../Hooks/RevealText";

function ServicesGraphicDesign() {
  const iconStyles = {
    left: "2%",
    width: "25%",
    maxWidth: "350px",
    minWidth: "280px",
    top: "0%",
    position: "absolute",
    opacity: "0.05",
  };

  const bgTitle = {
    opacity: "0.05",
  };

  const mixBlendMode = {
    mixBlendMode: "screen",
    filter: "blur(2px)",
    opacity: "0.8",
  };

  const iconcircle = {
    width: "80px",
    height: "80px",
    position: "absolute",
    borderRadius: "50%",
  };

  const iconGroup = {
    right: "80px",
    bottom: "100px",
  };

  const illustratorTools = {
    top: "20px",
    left: "10px",
    width: "60%",
    position: "absolute",
  };

  const creatingBrandingStyles = {
    opacity: 1,
  };

  return (
    <div>
      <div className="relative md:pt-16">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20 ">
          <div className="grid grid-cols-12 gap-2 z-2">
            <div className="p-5 lg:col-span-5 md:col-span-9 md:col-start-2 col-span-12 relative lg:mt-12">
              <div style={creatingBrandingStyles}>
                <ScrollEffect speed={8}>
                  <AnimatedAsset
                    duration={15000}
                    rotate={0}
                    minusrotate={0}
                    posx={-15}
                    minusx={20}
                    posy={0}
                    minusy={0}
                  ></AnimatedAsset>
                  <div style={illustratorTools}>
                    <ScrollEffect speed={-3}>
                      <img src={brandingLogo} />
                    </ScrollEffect>
                  </div>
                  <div className="absolute" style={iconGroup}>
                    <AnimatedAsset
                      duration={10000}
                      rotate={0}
                      minusrotate={0}
                      posx={14}
                      minusx={-14}
                      posy={5}
                      minusy={-10}
                    >
                      <div
                        className="bg-primary"
                        style={{ ...iconcircle, ...mixBlendMode }}
                      ></div>
                    </AnimatedAsset>

                    <AnimatedAsset
                      duration={8000}
                      rotate={0}
                      minusrotate={0}
                      posx={12}
                      minusx={-10}
                      posy={0}
                      minusy={0}
                    >
                      <div
                        className="bg-blue"
                        style={{ ...iconcircle, ...mixBlendMode }}
                      ></div>
                    </AnimatedAsset>

                    <AnimatedAsset
                      duration={5000}
                      rotate={0}
                      minusrotate={0}
                      posx={3}
                      minusx={3}
                      posy={-6}
                      minusy={8}
                    >
                      <div
                        className="bg-green"
                        style={{ ...iconcircle, ...mixBlendMode }}
                      ></div>
                    </AnimatedAsset>

                    <AnimatedAsset
                      duration={120000}
                      rotate={0}
                      minusrotate={0}
                      posx={18}
                      minusx={-12}
                      posy={10}
                      minusy={0}
                    >
                      <div
                        className="bg-yellow"
                        style={{ ...iconcircle, ...mixBlendMode }}
                      ></div>
                    </AnimatedAsset>

                    <AnimatedAsset
                      duration={50000}
                      rotate={0}
                      minusrotate={0}
                      posx={0}
                      minusx={0}
                      posy={0}
                      minusy={0}
                    >
                      <div
                        className="bg-secondary"
                        style={{ ...iconcircle }}
                      ></div>
                    </AnimatedAsset>
                  </div>
                  <img className="w-100 p-2 mx-auto" src={creatingBranding} />
                </ScrollEffect>
              </div>
            </div>

            <div class="lg:col-span-7 md:col-span-12 md:col-start-2 col-span-12 md:px-12">
              <RevealText>
                <h3 class="leading-tight md:text-2xl xl:text-3xl font-heading relative z-10 mb-8">
                  Bespoke Graphic Design To Power Your Marketing
                </h3>
              </RevealText>
              <Reveal>
                <p class="mb-8 relative z-10">
                  Is your marketing design material somewhat lacklustre? We have
                  the skill to marry your vision with an evidence-based design
                  approach to ensure we connect with your audience. Using colour
                  theory, layout and industry trends, we create tailor made
                  graphic design material to power your brand. Communication
                  through visual means enables you to target clients in a split
                  second.
                </p>
              </Reveal>
              <Reveal>
                <p class="mb-8 relative z-10">
                  We create one-of-a-kind graphic designs, specifically tailored
                  to maximise customer conversion rates. Graphic design is an
                  absolute must when developing all public facing products and
                  materials such social media ads, posters and web design in
                  order to evoke the right audience response and power your
                  marketing!
                </p>
              </Reveal>

              <Link
                alt="View graphic design page"
                to={`/services/content-marketing-graphic-design`}
                className="z-10"
              >
                <button class="my-0 mx-0 group inline-block text-dark leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                  Visit graphic design page
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 w-10 ml-2 group-hover:translate-x-4 transition transition-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ServicesGraphicDesign;
