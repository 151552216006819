import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { useSpring, animated, config, easings } from "react-spring";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";
import { Reveal } from "../../../Hooks/Reveal";
import { RevealText } from "../../../Hooks/RevealText";

function ServicesHome(props) {
  const { color, rotateY } = useSpring({
    from: {
      color: "#1728b2",
      rotateY: 10,
    },
    to: {
      color: "#ff503a",
      rotateY: -10,
    },
    config: {
      duration: 1000,
      easing: easings.easeInOutQuart,
    },
    loop: { reverse: true },
  });

  return (
    <div>
      <ChangeToPrimary
        className="bg-dark "
        colourFrom={"bgPrimary"}
        colourTo={"bgSecondary"}
      >
        <section className="container align-item mx-auto pt-18 px-6 py-10 min-h-screen content-center">
          <section className="container mx-auto py-24  md:py-24 lg:py-24">
            <div class="flex items-center flex-wrap">
              <div class="lg:col-span-7 md:col-span-12 md:col-start-1 col-span-12 md:px-12 content-center">
                <ScrollEffect speed={-3}>
                  <Reveal>
                    <h4 class="text-lg font-thin italic">
                      Get the right customers
                    </h4>
                    <h4 class="text-3xl md:text-5xl mb-16 leading-tight">
                      Supercharge Your Business With Plain Design Studio
                    </h4>
                  </Reveal>
                </ScrollEffect>

                <ScrollEffect speed={-3}>
                  <Reveal>
                    <p>
                      Getting the correct customers to your website is of utmost
                      importance for several compelling reasons. Firstly,
                      attracting the right audience ensures that your products
                      or services are showcased to those who are genuinely
                      interested, increasing the likelihood of conversions and
                      sales.
                    </p>
                  </Reveal>
                  <Reveal>
                    <p>
                      This targeted approach saves time, effort, and resources,
                      as you focus your marketing efforts on individuals who are
                      more likely to engage positively with your offerings.
                      Secondly, attracting the correct customers fosters loyalty
                      and satisfaction, as they find precisely what they were
                      seeking, leading to positive word-of-mouth and repeat
                      business.
                    </p>
                  </Reveal>
                  <Reveal>
                    <p>
                      Additionally, catering to the right audience allows you to
                      gather valuable data and feedback, enabling you to refine
                      your offerings and marketing strategies further.
                      Ultimately, by attracting the correct customers, your
                      website can thrive, fostering a strong brand reputation
                      and sustainable growth in a competitive digital landscape.
                    </p>
                  </Reveal>
                </ScrollEffect>
              </div>
            </div>
          </section>
        </section>
      </ChangeToPrimary>
    </div>
  );
}

export default ServicesHome;