import React, { Component } from "react";

export class FormPersonalData extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    let nextButton;
    let re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

    if (
      re.test(values.Client_Email)
      // values.Client_Phone.length > 7 ||
      // re.test(values.Client_Email) ||
      // re.test(values.Client_Email)
    ) {
      nextButton = (
        <button
          onClick={this.continue}
          class="text-dark group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        >
          Keep Going
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      );
    }

    return (
      <div className="w-full">
        <h5 className="text-md">Email & phone number</h5>
        <div class="flex justify-center">
          <div className="w-full">
            <div class="relative z-0 w-full mb-8 group">
              <input
                type="email"
                onChange={handleChange("Client_Email")}
                defaultValue={values.Client_Email}
                className="block pt-3 pb-1 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primaryfocus:outline-none focus:ring-0 focus:border-primary peer"
                id="floatingEmaile"
                placeholder=" "
                required
              />
              <label
                for="floatingEmaile"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:dark:text-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-primary"
              >
                email
              </label>
            </div>
            <div class="relative z-0 w-full mb-8 group">
              <input
                type="number"
                onChange={handleChange("Client_Phone")}
                defaultValue={values.Client_Phone}
                className="block pt-3 pb-1 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primaryfocus:outline-none focus:ring-0 focus:border-primary peer"
                id="floatingPhone"
                placeholder=" "
              />
              <label
                for="floatingPhone"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:dark:text-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-primary"
              >
                phone
              </label>
            </div>
            <div class="flex space-x-5 justify-between">
              <button
                onClick={this.back}
                class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>

              {nextButton}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPersonalData;