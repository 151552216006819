import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";

function WebsiteBody() {
  return (
    <div>
      <ChangeToPrimary>
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <Reveal reveal={"h4"}>
                <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                  Websites are an opportunity to communicate with potential
                  clients.
                </h4>
              </Reveal>

              <div className="grid grid-cols-12 absolute gap-8 top-0">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img className="w-100 opacity-10" src={thumb} />
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div class="md:col-span-7 col-span-12 z-10">
              <Reveal reveal={"new"}>
                <p class="mb-8 relative z-10">
                  Using the latest techniques in coding gives Plain Design
                  Studio the edge in website building. We are here to stay,
                  speed is a non negotiable, alongside never using generic
                  templates (Wix/Divi/elementor) and never outsourcing.
                  Everything developed by Plain Design Studio is custom made
                  in-house, enabling us to produce clean code, which results in
                  quicker websites. Remaining up-to-date with technology is a
                  full-time job.
                </p>
                <p class="mb-8 relative z-10">
                  We build SEO friendly code, which means it is easier to
                  promote your website. SEO (Search Engine Optimisation) is
                  often neglected by developers but it is an essential part of
                  our design process. Our builds are fast which translates to
                  high ranking pages on search engines such as Google. We also
                  reduce third party plugins and JS files, so as not to bloat
                  the site alongside both tagging and optimising any images used
                  on the site. This process ensures Google will choose to
                  display your website over templated, poorly executed sites,
                  it’s a known fact nobody checks the second page of google!
                </p>
              </Reveal>
            </div>
          </div>
        </section>
      </ChangeToPrimary>
    </div>
  );
}

export default WebsiteBody;
