import React from 'react'
import MobileMenu from "./MobileMenu";
import { default as closeCross } from "../images/menu/cross.svg";

function NavigationMenu(props) {
  return (
    <div>
      <nav className="p-5">
        <button className="absolute top-5 right-5" onClick={props.closeMenu}>
          <img width="20px" src={closeCross} />
        </button>

        <div className="mobile-nav align-middle h-full text-lg font-bold pt-16">
          <MobileMenu closeMenu={props.closeMenu} />
        </div>
      </nav>
    </div>
  );
}


export default NavigationMenu;
