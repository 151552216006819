import React from "react";
import TopBanner from "../TopBanner";
import EccommerceTopBanner from "../views/Eccommerce/EccommerceTopBanner";
import EccommerceIntro from "../views/Eccommerce/EccommerceIntro";
import CreateEccommerce from "../views/Eccommerce/CreateEccommerce";
import PaymentsEccommerce from "../views/Eccommerce/PaymentsEccommerce";
import EccommerceCTA from "../views/Eccommerce/EccommerceCTA";
import EccommerceSales from "../views/Eccommerce/EccommerceSales";
import EccommercePlatform from "../views/Eccommerce/EccommercePlatform";

function Ecommerce() {
  return (
    <>
      <EccommerceTopBanner />
      <EccommerceIntro />
      <CreateEccommerce />
      <PaymentsEccommerce />
      <EccommerceCTA />
      <EccommercePlatform />
      <EccommerceSales />
    </>
  );
}

export default Ecommerce;
