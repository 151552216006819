import React, { Component } from "react";
import { default as envelope } from '../../images/assets/envelope.svg'
import { Link } from "react-router-dom";

export class Confirm extends Component {

  submit = (e) => {
    e.preventDefault();
      //Process Form
    this.props.submitEmail(this.props.values);
  };
    
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
    
    render() {

        let emailSet;
        let phoneSet;
        let hourSet;
        let minuteSet;
        let pmSet;

      const { values: { firstName, lastName, email, city, date, time, phone, hour, minute, pm } } = this.props;
      if (email) {
          emailSet = <li>{email}</li>
      }
      if (phone) {
        phoneSet = <li>{phone}</li>
      }
      if (hour) {
        hourSet = <span>{hour}</span>
      }
      if (minute) {
        minuteSet = <span>{minute}</span>
      }
      if (pm) {
        pmSet = <span>{pm}</span>
      }
    return (
      <div className="">
        <h2 className="text-xl text-center leading-tight mb-12">
          Thank you for <br />
          your request{" "}
          <span className="text-primary">
            {this.props.values.Client_First_Name},
          </span>
          <br />
          we will be in contact soon!
        </h2>
        <img className="hidden mx-auto mb-5" width="80px" src={envelope} />

        <div class="flex space-x-5 justify-center mb-8">
          <Link to="/">
            <button class="group inline-block border border-primary bg-primary py-2 px-4 text-light text-base leading-tight rounded-full hover:bg-light hover:text-primary hover:border-primary focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
              Back Home
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Confirm;