import React, { useEffect, useState } from "react";
import Pagination from "./Casestudy/GetPosts";
import { useAxiosGet } from "../../Hooks/httpRequest";
import PaginationNav from "../../Hooks/PaginationNav";
import { useParams, useLocation } from "react-router-dom";
import TopBanner from "../TopBanner";
//import HelloWorld from '../HelloWorld'

export const Cases = () => {
  const { pagenumber } = useParams();

  const [page, setPage] = useState(null);

  function handleChange(newValue) {
    setPage(newValue);
  }

  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const itemsPerPage = 10;
  const [postData, setPostData] = useState();

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/case_study?page=${page}`;
  const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/case_study/v1/getpostqty`;

  let posts = useAxiosGet(url);
  let totalItems = useAxiosGet(totalQty);

  let content = null;

  useEffect(() => {
    if (pagenumber) {
      setPage(pagenumber);
    } else {
      setPage(1);
    }
  }, []);

  // if (posts.error) {
  //   content = (
  //     <div class="pt-24 flex items-center justify-center space-x-2">
  //       <h2>Opps, Looks like there is an error</h2>
  //     </div>
  //   );
  // }

  if (posts.loading) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  //Get current post

  //const paginate = (pageNumber) => setCurrentPage(pageNumber)

  if (posts.data) {
    content = (
      <Pagination
        items={posts}
        loading={posts.loading}
        currentPage={posts.currentPage}
        itemsPerPage={posts.itemsPerPage}
      />
    );
  }

  return (
    <div className="bg-white">
      <TopBanner
        header="Case studies"
        metaHeader="Case Studies in Various Industries with Plain Design"
        metaDescription="Plain Design Studio has rich experience of website development and digital design in healthcare, retail, ecommerce and financial services industries"
      />

      {content}

      <PaginationNav
        onChange={handleChange}
        totalItems={totalItems.data}
        itemsPerPage={itemsPerPage}
        currentPage={pagenumber}
      />
    </div>
  );
};

// export default Cases;
