import React from "react";
import { default as performanceImage } from "../../images/performance/performanceBanner.png";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { default as wordpressLogo } from "../../images/logo/images/wordpress.svg";
import { Helmet } from "react-helmet";
//wooCommerce
import { default as google } from "../../images/SEO/googleLogo.png";
import { RevealImage } from "../../../Hooks/RevealImage";
import { Reveal } from "../../../Hooks/Reveal";
//import { ParallaxProvider } from 'react-scroll-parallax';

const computerTopStyle = {
  top: "30px",
  left: "0%",
  width: "30%",
  maxWidth: "250px",
};

function TopBanner() {
  return (
    <div>
      <Helmet>
        <title>Keywords Analysis, On-page SEO, Technical SEO Services</title>
        <meta
          name="description"
          content="We provide full SEO services for your website and conduct technical SEO optimisation to improve the search engine index performance on both Google and Bing"
        />
      </Helmet>

      <div className="w-full overflow-clip bg-light">
        <div className="relative top-0 bg-light w-full md:py-16 py-28">
          <div className="relative container flex flex-wrap">
            <div className="w-100  lg:w-6/12 xl:w-6/12 relative">
              <div className="relative text-dark md:mb-24 md:mb-8">
                <div className="md:bg-transparent p-8 md:pb-0 md:mt-12 lg:mt-12 xl:mt-24">
                  <RevealImage>
                    <p className="text-primary text-sm mb-0 font-bold">
                      Drive Conversions, Boost ROI
                    </p>
                  </RevealImage>
                  <RevealImage>
                    <h1 className="relativez-50 lg:text-left text-3xl text-dark md:text-3xl lg:text-4xl xl:text-4xl leading-tight font-heading">
                      Performance Marketing & Optimisation
                    </h1>
                  </RevealImage>
                  <Reveal>
                    <p>
                      Unlock Your Business Potential with Cutting-Edge
                      Performance Marketing Solutions and Dominate Your Market
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>

            <div className="w-11/12 lg:w-6/12 xl:w-6/12 relative mx-auto bgTopImageWrap mt-24 md:mt-28 lg:mt-16">
              <ScrollEffect speed={-10}>
                <RevealImage>
                  <img
                    alt="App UX to increase performance marketing"
                    className="rounded-xl top-0 mx-auto"
                    style={{ minHeight: "100%", height: "auto" }}
                    src={performanceImage}
                  />
                </RevealImage>
              </ScrollEffect>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
