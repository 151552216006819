import React, { Component } from "react";
import FormUserDetails from "./FormUserDetails";
import FormPersonalData from "./FormPersonalData";
import FormServices from "./FormServices";
import Confirm from "./Confirm";
import Submitted from "./Submitted";
import { axiosSendEmail } from "../../../Hooks/axiosSendEmail";

export class UserForm extends Component {
  url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/awhitepixel/v1/emailData/`;

  state = {
    step: 1,
    email: "admin@plaindesignstudio.com",
    subject: "Enquire Form",
    Client_First_Name: "",
    Client_Last_Name: "",
    Client_Email: "",
    Client_Phone: "",
    Service: "",
  };

  //Go next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
    //window.scrollTo(0, 0);
    this.props.currentSteps(step + 1);
  };

  //Go back to prev
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
    //window.scrollTo(0, 0);
    this.props.currentSteps(step - 1);
  };

  //Go back to prev
  setNewDate = (e) => {
    const { date } = this.state;
    //   console.log(e);
    //const { date } = this.state;
    this.setState({
      date: "'" + e + "'",
    });
  };

  submitEmail = (e) => {
    const { step, dynamic_data, files } = this.state;
    const filesStr = files;
    this.setState({
      step: step + 1,
    });
    axiosSendEmail(this.url, e, filesStr, dynamic_data);
  };

  //Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { step } = this.state;
    const {
      Client_First_Name,
      Client_Last_Name,
      Client_Email,
      Client_Phone,
      service,
      email,
    } = this.state;
    const values = {
      Client_First_Name,
      Client_Last_Name,
      Client_Email,
      Client_Phone,
      service,
      email,
    };

    switch (step) {
      case 1:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <FormPersonalData
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <FormServices
            setNewDate={this.setNewDate}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );

      case 4:
        return (
          <Confirm
            prevStep={this.prevStep}
            submitEmail={this.submitEmail}
            values={values}
          />
        );

      case 5:
        return (
          <Submitted
            prevStep={this.prevStep}
            submitEmail={this.submitEmail}
            values={values}
          />
        );
    }
  }
}

export default UserForm;
