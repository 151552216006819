import React, { Component } from "react";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

export class Confirm extends Component {
  emailData = {
    email: this.props.values.email,
    subject:
      "Enquiry from: " +
      this.props.values.Client_First_Name +
      " " +
      this.props.values.Client_Last_Name,
    files: this.props.values.files,
  };

  emailBody = {
    Client_Name:
      this.props.values.Client_First_Name +
      " " +
      this.props.values.Client_Last_Name,
    Client_Email: this.props.values.Client_Email,
    Client_Phone: this.props.values.Client_Phone,
    Service_Request: this.props.values.service,
  };

  submit = (e) => {
    e.preventDefault();
    //Process Form
    this.props.submitEmail([this.emailBody, this.emailData]);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    let contact = "";

    if (this.props.values.Client_Phone && this.props.values.Client_Email) {
      contact = `phone number is ${values.Client_Phone} and email is ${values.Client_Email}`;
    } else if (
      this.props.values.Client_Phone &&
      !this.props.values.Client_Email
    ) {
      contact = `phone number is ${values.Client_Phone}`;
    } else if (
      !this.props.values.Client_Phone &&
      this.props.values.Client_Email
    ) {
      contact = `email is ${values.Client_Email}`;
    } else {
    }

    return (
      <div>
        <h5 className="text-xl text-center">
          Hey {this.props.values.Client_First_Name},
          <br /> your message is ready to send!
        </h5>
        <div class="flex space-x-5 justify-between">
          <button
            onClick={this.back}
            class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "rotate(180deg)" }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>

          <button
            onClick={this.submit}
            class="text-dark flex group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
          >
            Send Now!
            <AnimatedAsset
              duration={10000}
              rotate={0}
              minusrotate={0}
              posx={10}
              minusx={20}
              posy={0}
              minusy={0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="inline-block h-6 w-9 group-hover:translate-x-2 transition delay-100 transition-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </AnimatedAsset>
          </button>
        </div>
      </div>
    );
  }
}

export default Confirm;