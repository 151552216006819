import React, { useState, useEffect } from "react";
import GetPosts from "../views/Posts/GetPosts";
import GetFeaturedPosts from "../views/Posts/GetFeaturedPosts";
import { useAxiosGet } from "../../Hooks/httpRequest";
import PaginationNav from "../../Hooks/PaginationNav";
import { useParams } from "react-router-dom";
import TopBanner from "../TopBanner";
import CategoryLoop from "../../Hooks/CategoryLoop";
import { default as plainLogoMeta } from "../images/logo/plain-logo.png";
import { Helmet } from "react-helmet";

//import HelloWorld from '../HelloWorld'

function TopFivePosts(props) {
  const { pagenumber, catId } = useParams();

  const [page, setPage] = React.useState(pagenumber);

  useEffect(() => {
    if (pagenumber) {
      setPage(pagenumber);
    } else {
      setPage(1);
    }
    if (catId) {
      setCurrentCategory(catId);
    } else {
      setCurrentCategory(1);
    }
    //window.location.reload();
  }, []);

  const [currentCategory, setCurrentCategory] = React.useState(catId);

  function handleChange(newValue) {
    setPage(newValue);
  }

  function handleCat(newValue) {
    setCurrentCategory(newValue);
  }

  // console.log(page);

  console.log(currentCategory);

  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const itemsPerPage = 12;

  const categories = `&categories=${currentCategory}`;
  const categorcount = `?categories=${currentCategory}`;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/posts?per_page=6&page=${page}${categories}`;
  const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/posts/v1/getpostqty${categorcount}`;

  let posts = useAxiosGet(url);
  let totalItems = useAxiosGet(totalQty);

  let content = null;
  let featured = null;

  if (posts.error) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (posts.loading) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  //Get current post

  //const paginate = (pageNumber) => setCurrentPage(pageNumber)
  //console.log(currentPage)

  if (posts.data) {
    content = (
      <GetPosts
        items={posts}
        loading={posts.loading}
        currentPage={posts.currentPage}
        itemsPerPage={posts.itemsPerPage}
        loopFrom={0}
        loopTo={12}
      />
    );
  }

  // if (posts.data) {
  //   featured = (
  //     <GetFeaturedPosts
  //       items={posts}
  //       loading={posts.loading}
  //       currentPage={posts.currentPage}
  //       itemsPerPage={posts.itemsPerPage}
  //       loopFrom={4}
  //       loopTo={12}
  //     />
  //   );
  // }

  return (
    <div className="bg-content">
      <div className="relative">
        <div className="container">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-12 col-span-12 relative">{content}</div>
            <div className="hidden md:col-span-3 col-span-12 relative">
              <CategoryLoop
                onChange={handleCat}
                loopArgs="posts"
              ></CategoryLoop>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFivePosts;
