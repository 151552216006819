import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/primary/computer.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";

function SEOIntro() {
  return (
    <ChangeBGColour>
      <section className="container relative mx-auto px-4 py-24 z-10 md:py-12 lg:py-16 xl:py-28">
        <div className="grid grid-cols-12 gap-8">
          <div className="md:col-span-5 col-span-12  relative">
            <Reveal reveal={"h4"}>
              <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                What is SEO and how does it work?
              </h4>
            </Reveal>
          </div>

          <div class="md:col-span-7 col-span-12 z-10">
            <Reveal reveal={"body"}>
              <p class="relative z-10">
                There are so many reasons why SEO (search engine optimisation)
                is important for your business. Not only will it help{" "}
                <Link
                  className="text-white underline"
                  to={"/services/branding-marketing"}
                >
                  build more awareness of your brand
                </Link>{" "}
                and what you have to offer, it will build trust between you and
                your potential clients and help position you as a thought leader
                within your industry.
              </p>
              <p class="mb-8 relative z-10">
                The main goal of SEO is to get more organic traffic to your
                website and this is done through implementing a series of
                processes including generating high-quality, linkable content
                that perfectly matches the search intent of your future
                customers alongside the best possible user experience.
              </p>
              <p class="mb-8 relative z-10">
                These processes are called ‘algorithms’ and they take into
                account a huge number of ‘ranking factors’ - most of which are
                kept under lock and key, however our SEO experts will implement
                the most well known techniques to help your site climb up
                through the rankings and be seen by as many people as possible.
              </p>
            </Reveal>
          </div>
        </div>
      </section>
    </ChangeBGColour>
  );
}

export default SEOIntro;
