import React, { useEffect, useState} from 'react'
import Navigation from './Navigation/Navigation'
import { Link } from 'react-router-dom'
import { default as logo } from "./images/logo/logo-icon.svg";
import { RevealText } from "../Hooks/RevealText";
import { RevealImage } from "../Hooks/RevealImage";

function Header() {
  // storing this to get the scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0);
  // the offset of the document.body
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  // the vertical direction
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  // the horizontal direction
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  // scroll direction would be either up or down
  const [scrollDirection, setScrollDirection] = useState();
  let colorChange = "bg-none";
  let logotext = "regular";

  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  if (scrollY > 20) {
    colorChange = "bg-white";
    logotext = "textNone";
  }
  if (scrollY < 50 || scrollY === 0) {
    colorChange = "bg-white";
    logotext = "regular";
  }

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
      background: "white",
    },
    hidden: {
      //visibility: "hidden",
      //background: "bg-primary",
      transition: "all 0.5s",
      transform: "translateY(-150%)",
    },
  };

  const logoStyles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s",
    },
    hidden: {
      //visibility: "hidden",
      //background: "bg-primary",
      transition: "all 0.5s",
      transform: "translateX(0%) scale(0) rotate(-360deg)",
    },
  };

  return (
    <div
      className="w-full fixed w-full mx-auto flex content-center"
      style={{ zIndex: 9999, height: "1px" }}
    >
      <nav
        style={
          scrollDirection === "down" || scrollY <= 10
            ? styles.active
            : styles.hidden
        }
        className={`flex relative header-nav justify-end mx-auto  lg:w-11/12 w-11/12 mt-4  ${colorChange}`}
      >
        <span className="font-bold flex items-center justify-center">
          <Link
            className={`flex left-0 top-0 bg-primary absolute rounded-full overflow-hidden`}
            style={
              scrollDirection === "down" || scrollY <= 10
                ? logoStyles.active
                : logoStyles.hidden
            }
            to="/"
          >
            <div style={{ width: "56px", height: "56px" }}>
              <img
                alt="Plain Design Studio Logo"
                width="100%"
                className="py-3 p-3"
                src={logo}
              />
            </div>

            <div className={`relative logoTypeWrapper mt-3 `}>
              <span
                className={`font-thin text-white logoType relative align-middle ${logotext}`}
              ></span>
            </div>
          </Link>
        </span>

        <Navigation className="bg-light rounded" />

        <button className="h-100  md:block text-white text-sm px-4 font-bold bg-primary rounded hover:bg-primaryDark">
          <Link className="text-white" to={"/contact"}>
            {" "}
            Contact Us{" "}
          </Link>
        </button>
      </nav>
    </div>
  );
}


export default Header