import React from "react";
import HomeGraphicDesign from "./Home/HomeGraphicDesign";
import HomeBranding from "./Home/HomeBranding";
import ServicesHome from "./Home/ServicesHome";
import TopBanner from "./Home/TopBanner";
import HomeIntro from "./Home/HomeIntro";
import CarouselCases from "./CarouselCases";
import HomeWebDesign from "./Home/HomeWebDesign";
import ServiceBlock from "./Home/ServiceBlock";
import Process from "./Home/Process";
import TopFivePosts from "../Pages/TopFivePosts";
import { Link } from "react-router-dom";
//import ServicesHome from "../../../../my-project/src/components/views/Home/HomeAboutBlock";
//import ParallaxCache from "./ParallaxCache";

// import BottomBanner from "../BottomBanner"
//import { useParams } from 'react-router-dom';
//import HelloWorld from '../HelloWorld'

export default function Home() {
  const svgStyleTop = {
    position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const svgStyleBottom = {
    position: "absolute",
    color: "white",
    width: "100%",
    bottom: "-1px",
  };

  return (
    <div>
      <TopBanner className="z-10" />
      <HomeIntro />
      <CarouselCases className="relative z-10 bg-light" />
      <ServiceBlock />
      <ServicesHome />
      {/* <ServiceBlock /> */}
      <div className="py-12">
        <h5 className="text-xl text-center mb-4 mt-12">Recent articles</h5>
        <TopFivePosts />
        <div className="text-center pt-4">
          <Link alt="all articles" to={`/posts/page/1/1`} className="">
            <button class="mx-auto text-center mx-auto group inline-block text-dark leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
              View all articles
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="inline-block h-8 w-10 ml-2 group-hover:translate-x-4 transition transition-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

//export default Home;
