import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as rocket } from "../../images/assets/rocket.svg";
import { default as websiteImage } from "../../images/assets/computer-web-design.jpg";
import { default as smilingman } from "../../images/assets/primary/smiling-man.svg";

function ServiceWebsite(props) {
  const smilingmanStyles = {
    top: "0px",
    left: "-50px",
    width: "20%",
    maxWidth: "150px",
    minWidth: "80px",
    position: "absolute",
  };

  const iconStyles = {
    right: "2%",
    width: "15%",
    maxWidth: "250px",
    minWidth: "80px",
    bottom: "0%",
    position: "absolute",
    opacity: 1,
  };

  return (
    <div>
      <div className="relative bg-light">
        <section className="container mx-auto">
          <div style={iconStyles}></div>
          <div className="">
            <section className="container relative mx-auto px-4 py-18 z-10 md:py-32 lg:py-32">
              <div className="grid grid-cols-12 gap-8">
                <div className="md:col-span-6 lg:col-span-5 col-span-12 mx-auto">
                  <ScrollEffect speed={8}>
                    <div style={smilingmanStyles}>
                      <ScrollEffect rotateFrom={-40} rotateTo={10} speed={2}>
                        <img src={smilingman} />
                      </ScrollEffect>
                    </div>
                    <img
                      className="w-100 p-2 mx-auto"
                      src={websiteImage}
                      style={{ minHeight: "300px" }}
                    />
                  </ScrollEffect>
                </div>
                <div className="md:col-span-6 col-span-12  relative">
                  <ScrollEffect speed={-3}>
                    <h4 class="text-lg mb-2 font-thin italic text-blue">
                      Connect to your customers
                    </h4>
                    <h4 class="text-4xl mb-5 text-dark">
                      Supercharge Your Business With Plain Design{" "}
                      <span class="underlineSvg">Studio</span>
                    </h4>
                  </ScrollEffect>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ServiceWebsite;
