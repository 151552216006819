import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/primary/computer.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";

function WebsiteIntro() {
  return (
    <div>
      <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
        <div className="grid grid-cols-12 gap-8">
          <div className="md:col-span-5 col-span-12  relative">
            <Reveal reveal={"h4"}>
              <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                <span className="toplinesSvg">Your</span> website is an
                extension of you and your company.
              </h4>
            </Reveal>

            <div className="grid grid-cols-12 absolute gap-8 top-0">
              <div className="col-span-12">
                <ScrollEffect
                  className=""
                  speed={-10}
                  rotateFrom={30}
                  rotateTo={-15}
                >
                  <img className="w-100 opacity-10" src={thumb} />
                </ScrollEffect>
              </div>
            </div>
          </div>

          <div class="md:col-span-7 col-span-12 z-10">
            <p class="mb-8 relative z-10">
              Here at Plain Design Studio, we are passionate about website
              design and we strive to create the best websites possible. The
              first objective is to understand your business and clarify your
              goals, which is essential to creating a long-lasting website. Grow
              your business with a bespoke website.
            </p>
            <p class="mb-8 relative z-10">
              We will give you a solid idea of what a website can do for your
              company. They are an essential tool for most businesses. Defining
              the objective of your website is essential to its success. Having
              clear intentions is the perfect first step in building a
              long-lasting site, which performs to its maximum potential.
              Knowing your customer base is essential to developing an online
              presence. Before we begin your website design, you should have
              clear branding and a prominent mission statement (we can help with
              this).
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WebsiteIntro;
