import React, { useState } from "react";
import UserForm from "../views/Contact/UserForm";
import { Helmet } from "react-helmet";
import "../css/stepperStyle.css";

function Contact() {
  const [currentStep, setCurrentSteps] = useState(1);
  const stepCount = 4;

  const stepNav = [];
  for (let i = 0; i < stepCount; i++) {
    stepNav.push(
      <li class="flex stepper-li justify-center w-full items-center mb-0">
        <div
          class={`stepper-head hover:bg-white
        ${currentStep === i + 1 ? "" : ""}
        `}
        >
          <span
            class={`flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${
              currentStep === i + 1
                ? "bg-primary text-light border border-primary scale-100 transition duration-300 ease-in-out"
                : ""
            }
              ${
                currentStep < i + 1
                  ? "bg-light text-dark border border-primary  scale-50"
                  : ""
              }

              ${
                currentStep > i + 1
                  ? "bg-primary border border-primary text-white scale-50"
                  : ""
              }
              `}
          >
            {i + 1}
          </span>
        </div>
      </li>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Start your marketing project</title>
        <meta
          name="description"
          content="Contact us today to get a new website or branding for your business."
        />
      </Helmet>
      <div className="flex bg-white relative">
        <div class="container">
          <div class="grid grid-cols-12 gap-4 p-5 py-32">
            <div class="col-span-12 sm:col-span-12 md:col-span-10 md:col-start-2 lg:col-start-2 lg:col-span-10 xl:col-span-8 xl:col-start-3 relative">
              <h1 class="text-md text-center mb-2 text-primary">
                Start your project
              </h1>
              <p class="text-center text-sm mb-0">
                {currentStep} of {stepCount} steps
              </p>
              <ol class="flex stepper items-between w-100 mx-0 p-2 my-4 rounded-lg">
                {stepNav}
              </ol>
              <UserForm currentSteps={setCurrentSteps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
