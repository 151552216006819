import React, { useState } from "react";
import { useParallax } from "react-scroll-parallax";
import { default as borderIMG } from "../../images/assets/border.svg";
import { default as borderLeftIMG } from "../../images/assets/borderLeft.svg";
import football from "../../images/assets/pngwing.com.png";
import kickboxing from "../../images/assets/kickboxer.png";
import { RevealImage } from "../../../Hooks/RevealImage";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import HomeWebDesign from "./HomeWebDesign";
import HomeBranding from "./HomeBranding";
import { RevealText } from "../../../Hooks/RevealText";
import HomeGraphicDesign from "./HomeGraphicDesign";
import { Link } from "react-router-dom";

function ServiceBlock() {
  const bgImage = {
    backgroundImage: `url(${football})`,
  };
  const bgkickboxing = {
    backgroundImage: `url(${kickboxing})`,
    bottom: "0px",
  };

  const triangleImage = {
    left: "-50px",
    top: "-50px",
    transform: "rotate(40deg)",
  };

  return (
    <section className="body-font z-10 text-white bg-blue">
      <div className="container py-12 mx-auto relative">
        <div class="grid grid-cols-12 gap-x-4 px-4">
          <div className="lg:col-span-12 col-span-12 pb-12">
            <div class="grid grid-cols-12">
              <div class="xl:col-span-6 md:col-span-8 sm:col-span-8  col-span-12 rounded h-100">
                <RevealText>
                  <h3 className="text-xl lg:text-2xl xl:text-4xl mb-3 leading-tight font-heading">
                    Our Services
                  </h3>
                </RevealText>
                <RevealText>
                  <p className="leading-tight font-heading text-light font-semibold text-md">
                    Elevate Your Brand with Comprehensive Design and Marketing
                    Solutions
                  </p>
                </RevealText>
                <RevealText>
                  <p>
                    At Plain Design Studio, we offer a range of tailored design
                    and digital marketing services to help your business thrive.
                    From crafting a memorable brand identity and designing a
                    stunning website to boosting your online presence with SEO
                    and PPC advertising, we combine creativity with strategy to
                    deliver results that drive your success. Explore our social
                    media management and e-commerce solutions to see how we can
                    transform your brand and elevate your business.
                  </p>
                </RevealText>

                <Link to="/services">
                  <RevealText reveal={false}>
                    <button class="text-blue bg-light mt-2 mb-2 ms-1 px-6 pr-8 py-1 text-sm  group inline-block fw-900 leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                      Our Services
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </button>
                  </RevealText>
                </Link>
              </div>
            </div>
          </div>

          <div class="xl:col-span-3 md:col-span-6 sm:col-span-12 col-span-12 rounded h-100">
            <RevealText delay="0">
              <div class="content-center rounded-xl py-8 relative z-10">
                <RevealText>
                  <h4 class="leading-tight font-heading relative z-10 text-lg">
                    Expert Website Design and Development
                  </h4>
                </RevealText>
                <RevealText delay="0.3">
                  <p>
                    Discover how expert website design and development can
                    elevate your online presence. From crafting visually
                    stunning and user-friendly interfaces to ensuring seamless
                    functionality, our team delivers tailored solutions that
                    enhance user experience and drive engagement. Learn how we
                    create websites that not only look great but also perform
                    exceptionally well.
                  </p>
                </RevealText>
              </div>
            </RevealText>
          </div>

          <div class="xl:col-span-3 md:col-span-6 sm:col-span-12 col-span-12 rounded h-100">
            <RevealText delay="0.3">
              <div class="content-center rounded-xl py-8 relative z-10">
                <RevealText>
                  <h4 class="leading-tight font-heading relative z-10 text-lg">
                    Cutting-Edge Performance Marketing Strategies
                  </h4>
                </RevealText>
                <RevealText delay="0.2">
                  <p>
                    Unlock the potential of your marketing budget with
                    performance marketing. Our data-driven strategies focus on
                    optimizing ad campaigns and tracking results to ensure
                    maximum return on investment. Explore how our targeted
                    approaches can boost your visibility, drive qualified leads,
                    and achieve measurable results for your business.
                  </p>
                </RevealText>
              </div>
            </RevealText>
          </div>
          <div class="xl:col-span-3 md:col-span-6 sm:col-span-12 col-span-12 rounded h-100">
            <RevealText delay="0.6">
              <div class="content-center rounded-xl py-8 relative z-10">
                <RevealText>
                  <h4 class="leading-tight font-heading relative z-10 text-lg">
                    Customized E-Commerce Website Solutions
                  </h4>
                </RevealText>
                <RevealText delay="0.2">
                  <p>
                    Take your online store to the next level with our bespoke
                    e-commerce website solutions. We design and develop secure,
                    user-friendly platforms that enhance the shopping experience
                    and increase conversions. Discover how our tailored
                    e-commerce services can help you attract customers,
                    streamline operations, and drive sales growth.
                  </p>
                </RevealText>
              </div>
            </RevealText>
          </div>
          <div class="xl:col-span-3 md:col-span-6 sm:col-span-12 col-span-12 rounded h-100">
            <RevealText delay="0.9">
              <div class="content-center rounded-xl py-8 relative z-10">
                <RevealText>
                  <h4 class="leading-tight font-heading relative z-10 text-lg">
                    Achieve Top Rankings with SEO Strategies
                  </h4>
                </RevealText>
                <RevealText delay="0.12">
                  <p>
                    Climb to the top of search engine results with our expert
                    SEO services. Our comprehensive approach includes keyword
                    research, on-page optimization, and technical improvements
                    to enhance your website’s visibility. Find out how we can
                    help you achieve higher rankings, attract more traffic, and
                    grow your online presence with proven SEO techniques.
                  </p>
                </RevealText>
              </div>
            </RevealText>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-2 py-12 px-4">
          <div className="md:col-span-5 col-span-12  relative">
            <RevealText delay="0.5">
              <h2 class=" text-xl leading-tight mb-4 md:text-xl lg:text-1xl lg:text-3xl font-heading relative z-10">
                Elevate Your Brand Through Innovative Design and Marketing
              </h2>
            </RevealText>

            {/* <div className="w-100 absolute">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img
                      alt="Thumb illustration"
                      className="w-100 opacity-10"
                      src={thumb}
                    />
                  </ScrollEffect>
                </div>
              </div> */}
          </div>
          <div class="md:col-span-7 col-span-12 z-10">
            <RevealText>
              <p class="mb-8 relative z-10">
                Our mission is simple yet profound: to elevate your brand by
                providing creative and effective design and marketing solutions.
                We believe in the power of design to communicate, inspire, and
                drive results. Our approach is rooted in understanding your
                unique business goals and delivering tailored strategies that
                align with your vision.
              </p>
            </RevealText>
            <RevealText>
              <p class="mb-8 relative z-10">
                Plain Design Studio was founded with a vision to create a space
                where creativity and strategy intersect. What began as a small
                initiative has grown into a leading design and marketing agency
                known for its innovative approach and commitment to excellence.
                Our journey has been marked by a dedication to quality, a
                passion for design, and a drive to help our clients succeed.
              </p>
            </RevealText>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceBlock;
