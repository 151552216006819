import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/primary/computer.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";

function EccommerceIntro() {
  return (
    <div>
      <div className="bg-primary">
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32 text-light">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <Reveal reveal={"h4"}>
                <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                  Why build an online store to sell your products?
                </h4>
              </Reveal>

              <div className="grid grid-cols-12 absolute gap-8 top-0">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img className="w-100 opacity-10" src={thumb} />
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div class="md:col-span-7 col-span-12 z-10">
              <p class="relative z-10">
                Online shopping has been on the rise for years, but the global
                pandemic in 2020 caused a huge surge in online retail sales and
                it’s now become the new normal.
              </p>

              <p class="relative z-10">
                Shoppers want to buy whatever they want, whenever they want -
                and to do so easily, quickly and safely.
              </p>

              <p class="relative z-10">
                Ecommerce gives you the ability to increase your sales
                indefinitely, selling locally or globally, whether you
                manufacture your own products or use dropshipping.
              </p>

              <p class="mb-8 relative z-10">
                A well run online business can make you money while you sleep,
                so get in touch with our team and let’s get started.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EccommerceIntro;
