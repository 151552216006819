import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as board } from "../../images/SEO/board.png";
import { default as googleIcon } from "../../images/SEO/googleIcon.png";
import { default as searchBar } from "../../images/SEO/searchBar.png";
import { default as rating } from "../../images/eccommerce/star-rating.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function SEOOnPage() {
  const googleIconStyle = {
    bottom: "20%",
    left: "5%",
    minWidth: "150px",
    width: "40%",
    position: "absolute",
    zIndex: 20,
  };

  const webPageStyle = {
    bottom: "30%",
    width: "100%",
    minWidth: "220px",
    height: "auto",
    left: "-100%",
    position: "absolute",
  };

  const searchBarStyle = {
    top: "15%",
    right: "10%",
    width: "60%",
    position: "absolute",
    zIndex: 2,
  };

  const ratingStyle = {
    top: "0%",
    left: "20px",
    width: "60%",
    position: "absolute",
    zIndex: 2,
  };

  const dashboardStyle = {
    bottom: "0px",
    right: "-8%",
    width: "80%",
    minWidth: "220px",
    position: "absolute",
  };

  return (
    <div>
      <div className="relative bg-primary text-white">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <h3 class="leading-tight font-heading relative z-10 mb-8">
                On-page and off-page SEO
              </h3>
              <p>
                Successful SEO techniques fall into one of two categories,
                <Link
                  className="text-white underline"
                  to={"/services//website-design"}
                >
                  on-page
                </Link>{" "}
                and off-page.
              </p>
              <p class="mb-8 relative z-10">
                In simple terms, on-page SEO is focussed on creating a website
                that is quick, easy for users to navigate and provides good
                quality content relevant to what they’re looking for. This takes
                into account aspects such as keywords, page speed, page titles,
                mobile responsiveness etc.
              </p>

              <p class="mb-8 relative z-10">
                Meanwhile, off-page SEO looks at the more complex tactics that
                can be used to promote your content via quality backlinks from
                respected and relevant third party websites with good domain
                authority. Your social media and Google Business Profile
                (previously known as Google My Business) also contribute to your
                off-page SEO efforts.
              </p>
            </div>

            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0 lg:px-8 order-first lg:order-last">
              <div className="relative">
                <div style={googleIconStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={-15}
                    minusx={30}
                    posy={0}
                    minusy={0}
                  >
                    <img src={googleIcon} />
                  </AnimatedAsset>
                </div>

                <div style={searchBarStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect YFrom={-8} YTo={8}>
                      <img src={searchBar} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <div style={ratingStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect XFrom={20} XTo={0} speed={0.5}>
                      <img src={rating} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect YFrom={-8} YTo={8}>
                  <img className="w-100 p-2 mx-auto right-0" src={board} />
                </ScrollEffect>

                <div className="absolute" style={{ ...webPageStyle }}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SEOOnPage;
