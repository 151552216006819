import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";

export const BrandingAudience = (props) => {
  return (
    <div>
      <div className="relative bg-light">
        <section className="container relative mx-auto px-4 py-8 z-10 py-12 md:py-24 lg:py-24">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <Reveal reveal={"h4"}>
                <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                  <span className="toplinesSvg">Target</span> your audience with
                  an emotional response
                </h4>
              </Reveal>
            </div>

            <div className="md:col-span-7 col-span-12  relative  relative ">
              <p class="relative z-10">
                Many companies offer the same product or service as others, but
                why do some succeed where others fail? This could be because
                they provide a better service than their competition, but
                oftentimes its not that simple. The common similarity between
                successful companies is a strong brand. Not only will a strong
                brand allow you to quickly differentiate yourself from the
                competition, it also allows you to connect to a specific
                audience.
              </p>
              <p class="relative z-10">
                A niche audience is a strategy wfeared by many companies. Not
                many people want to niche down into a smaller demographic as
                they worry about alienating general consumers. Without a clear
                target audience, your company brand will inevitably suffer.
                Trying to please everyone will result in pleasing noone. The end
                result? Your company being ignored by everyone. This is true for
                some of the most successful brands around. Take 'The North Face'
                whose niche is outdoor enthusiasts requiring high quality
                clothing. Now sported up & down the high street, they owe their
                success in the mainstream to staying loyal to their target
                audience and core values.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
