import React, { useState } from "react";
import GetCarousel from "./CarouselCasestudy/GetCarousel";
import { useAxiosGet } from "../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import ScrollEffect from "../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";
import { RevealText } from "../../Hooks/RevealText";

function CarouselCases() {
  //const itemsPerPage = 5
  //const [currentPage, setCurrentPage] = useState(1);

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/case_study?per_page=5`;
  //const totalQty = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/posts/v1/getpostqty`

  let posts = useAxiosGet(url);
  // let totalItems = useAxiosGet(totalQty)

  let content = null;

  if (posts.error) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <h2></h2>
      </div>
    );
  }

  if (posts.loading) {
    content = (
      <div class="pt-24 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  //Get current post

  //const paginate = (pageNumber) => setCurrentPage(pageNumber)
  //console.log(currentPage)

  if (posts.data) {
    content = <GetCarousel items={posts} loading={posts.loading} />;
  }

  return (
    <div>
      <div className="w-full bg-light relative">
        <div className="relative z-10 pt-12 md:pb-8">
          <div className="relative container px-4 flex flex-wrap">
            <div className="lg:w-8/12 w-12/12 relative">
              <div className="relative">
                <RevealText>
                  <h3 className="text-2xl relativez-50  leading-tight font-heading">
                    Our work
                  </h3>
                  <p>
                    Explore a curated selection of our standout projects that
                    demonstrate our expertise in design and digital marketing.
                    Each project highlights our ability to deliver
                    results-driven solutions tailored to our clients' needs.
                  </p>
                </RevealText>
              </div>
            </div>
          </div>
        </div>
      </div>
      {content}
    </div>
  );
}
export default CarouselCases;
