import React, {useState} from 'react'
import PostCard from './PostCard'


const GetPosts = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage, firstItems] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div class="">
      <div class="container w-100">
        <div className="grid grid-cols-12 gap-2 pt-12 px-2">
          {currentItems.map((post) => (
            <div
              className="xl:col-span-6 lg:cols-span-6 md:col-span-12 col-span-12"
              key={post.id}
            >
              <PostCard post={post} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetPosts;

