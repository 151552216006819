import React, { useRef } from "react";
import { default as thumbs } from "./images/assets/thumbs.svg";
import { default as cat } from "./images/assets/cat.svg";
import { default as coin } from "./images/assets/coin.svg";
import { default as computer } from "./images/assets/computer.svg";
import { default as lineBottom } from "./images/assets/lineBottom.svg";
import { default as lineTop } from "./images/assets/lineTop.svg";
import { default as plainLogoMeta } from "./images/logo/plain-logo.png";
import { Reveal } from "../Hooks/Reveal";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import ScrollEffect from "../Hooks/ScrollEffect";
import "./css/assets.css";
import ScrollBlur from "../Hooks/ScrollBlur";
import { Helmet } from "react-helmet";
import { RevealText } from "../Hooks/RevealText";
//import { ParallaxProvider } from 'react-scroll-parallax';

const catStyles = {
  top: "10%",
  right: "5%",
  maxWidth: "220px",
  minWidth: "180px",
  width: "25%",
};

const thumbStyles = {
  bottom: "15%",
  left: "-2%",
  width: "25%",
  maxWidth: "220px",
  minWidth: "180px",
};

const coinStyles = {
  top: "10%",
  left: "15%",
  width: "20%",
  maxWidth: "220px",
  minWidth: "180px",
};

const computerStyles = {
  bottom: "-10%",
  right: "15%",
  width: "30%",
  maxWidth: "350px",
};

function TopBanner(props) {
  const textSlow = useParallax({
    speed: -20,
  });

  const SVGTop = useParallax({
    speed: 20,
    startScroll: -280,
    endScroll: 280,
    //easing: 'easeInCirc',
  });

  return (
    <div>
      <Helmet>
        <title>{props.metaHeader}</title>
        <meta name="description" content={props.metaDescription} />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" content={`${plainLogoMeta}`} />
      </Helmet>

      <div className="container-fluid top-0 relative bg-light z-8 overflow-hidden">
        <div className="relative">
          <ScrollEffect speed={20} startScroll={-280} endScroll={280}>
            <img
              ref={SVGTop.ref}
              className="absolute top-0 right-0 topLineStyle"
              src={lineTop}
            />
          </ScrollEffect>
          <div
            className="w-100 hidden top-0 backgroundImage absolute bg-gradient-to-t from-dark"
            style={{
              height: "75vh",
            }}
          ></div>
          <div className="w-100 relative">
            <div
              class="flex justify-center items-center"
              style={{ height: "50vh", minHeight: "400px" }}
            >
              <div class="pt-28 px-8">
                <ScrollEffect speed={-20} startScroll={-400} endScroll={380}>
                  <RevealText>
                    <h6 class="text-blue text-sm font-light">
                      {props.topHeader}
                    </h6>
                  </RevealText>
                  <RevealText>
                    <h1 className="relativez-50 text-2xl md:text-3xl  xl:text-4xl text-dark leading-tight font-heading">
                      {props.header}
                    </h1>
                  </RevealText>
                </ScrollEffect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-50 w-full overflow-clip hidden">
        <div className="relative top-0 bg-light w-100 py-12 h-50">
          <img
            ref={SVGTop.ref}
            className="absolute top-0 right-0 topLineStyle"
            src={lineTop}
          />

          <div className="relative container px-4 flex flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-8/12 lg:w-8/12 mx-auto text-center relative">
              <div className="relative text-white md:my-24 my-8">
                <div ref={textSlow.ref} className="rounded-xl p-8">
                  <h6 class="text-primary">{props.topHeader}</h6>
                  <h1 className="relativez-50 text-dark md:text-4xl lg:text-4xl xl:text-5xl leading-tight font-heading">
                    {props.header}
                    <div className="hidden relative" width="100px">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 2000 100"
                        preserveAspectRatio="xMaxYMax"
                      >
                        <g>
                          <path
                            className="fill-primary"
                            d="M35.7,42.3C159.7,20.2,285.4,8.6,411.1,3.6C505.5,0.1,600.2,0.5,694.4,8c30.8,2.3,63.5,5.8,94.1,9.7
		c25.4,4,21.6,40.8-4.5,39.1C629.7,41.9,474,37.6,319,45.3C230,49.5,140.5,57,52.1,69c-3.9,0.5-7.8,1.1-11.5,1.7
		C22.2,72.8,17.3,46.7,35.7,42.3L35.7,42.3z"
                          />
                        </g>
                      </svg>
                    </div>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
