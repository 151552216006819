import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import { default as defaultImage } from "../../images/assets/computer-web-design.jpg";
import { Reveal } from "../../../Hooks/Reveal";

function PostCard(props) {
  const { title, excerpt, featured_media, id, date, slug } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString("default", { day: "2-digit" });
  const month = newdate.toLocaleString("default", { month: "short" });
  const year = newdate.toLocaleString("default", { year: "numeric" });
  const formatteddate = day + ", " + month + " " + year;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div
        style={{
          minHeight: "350px",
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url("${defaultImage}")`,
        }}
        class="rounded-lg drop-shadow-md	 overflow-hidden inset-0 h-full w-full object-cover object-center inset-0 w-full object-cover object-center transform transition duration-500 hover:scale-110"
      ></div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  //Get current post

  if (image.data) {
    FeaturedImage = (
      <div
        style={{
          minHeight: "350px",
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url("${image.data.media_details.sizes.full.source_url}")`,
        }}
        class="rounded-lg drop-shadow-md	 overflow-hidden inset-0 h-full w-full object-cover object-center inset-0 w-full object-cover object-center transform transition duration-500 hover:scale-110"
      ></div>
    );
  }

  const postCard = useParallax({
    //rotate: [-2, 5],
    //translateX: ['-5', '10'],
  });

  return (
    <Reveal>
      <div
        ref={postCard.ref}
        v-for="card in cards"
        class="flex flex-col overflow-hidden mt-0 w-100 mb-4 md:mx-2"
      >
        <div class="w-100 rounded-xl shadow overflow-hidden">
          <Link to={`/posts/${slug}`}>{FeaturedImage}</Link>
        </div>

        <Link
          class="md:w-full w-1/1 py-4 px-4 flex flex-col justify-between text-dark"
          to={`/posts/${slug}`}
        >
          <Reveal>
            <h4
              class="text-lg outLineText leading-tight mb-2"
              dangerouslySetInnerHTML={{ __html: title.rendered }}
            ></h4>
            <p class="text-sm leading-tight truncate bold">{formatteddate}</p>

            <p
              className="text-base mb-0 taxt-darklt"
              dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
            ></p>
          </Reveal>
          <button class="hidden opacity-7 text-left group inline-block bg-none py-2 font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
            Read more
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
              fill="primary"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>
        </Link>
      </div>
    </Reveal>
  );
}

export default PostCard;
