import React from "react";
import { useAxiosGet } from '../../../Hooks/httpRequest'
import { useParallax } from "react-scroll-parallax";
import CategoryLoop from "../../../Hooks/CategoryLoop";
import { Helmet } from "react-helmet";
import { RevealImage } from "../../../Hooks/RevealImage";
import { useLocation } from "react-router-dom";

function HeaderView() {
  const location = useLocation();
  console.log(location.pathname);
  return <span>Path : {location.pathname}</span>;
}

function PostContent(props) {
  const { title, content, featured_media, excerpt } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post
  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }

  const banner = useParallax({
    speed: -10,
  });

  const bannerText = useParallax({
    speed: -10,
  });

  return (
    <div>
      <Helmet>
        <title>{title.rendered}</title>
        <meta name="description" content={excerpt.rendered} />
        <meta property="og:type" key="og:type" content="article" />
        <meta property="og:image" content={FeaturedImage} />

        <meta property="og:url" content="" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />

        <meta name="twitter:card" content={FeaturedImage} />
        <meta property="twitter:domain" content="plaindesignstudio.com.au" />
        <meta property="twitter:url" content={HeaderView()} />
        <meta name="twitter:title" content={title.rendered} />
        <meta name="twitter:description" content={excerpt.rendered} />
        <meta name="twitter:image" content={FeaturedImage} />
      </Helmet>
      <div
        ref={banner.ref}
        className="container-fluid top-0 relative bg-dark z-8"
      >
        <div className="relative col-8 ">
          <div
            className="w-100 top-0 background-image absolute text-dark"
            style={{
              backgroundImage: `url(${FeaturedImage})`,
              height: "95vh",
            }}
          ></div>
          <div className="container relative min-h-screen">
            <div
              class="flex justify-center items-center"
              style={{ height: "95vh" }}
            >
              <div ref={bannerText.ref} class="pt-32 text-center">
                <RevealImage>
                  <h1
                    class="xl:text-5xl md:p-4 lg:text-2xl text-xl text-white"
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                  ></h1>
                </RevealImage>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white relative z-10 pt-8">
        <div class="container text-dark">
          <div class="grid grid-cols-12 gap-4 mb-8">
            <div class="col-span-12 md:col-span-8 m-2 ">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: content.rendered }}
              ></div>
            </div>
            <div class="col-span-12 md:col-span-4 m-2">
              <CategoryLoop />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostContent;
