import React, { useState } from "react";
import { useParallax } from "react-scroll-parallax";
import { default as borderIMG } from "../../images/assets/border.svg";
import { default as borderLeftIMG } from "../../images/assets/borderLeft.svg";
import football from "../../images/assets/pngwing.com.png";
import kickboxing from "../../images/assets/kickboxer.png";

function Process() {
  return (
    <div>
      <h3>Process</h3>
    </div>
  );
}

export default Process;
