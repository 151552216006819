import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as ranking } from "../../images/SEO/ranking.png";
import { default as optimisationBG } from "../../images/performance/performancebg.png";
import { default as plus } from "../../images/SEO/plus.png";
import { default as google } from "../../images/SEO/googleLogo.png";
import { default as rating } from "../../images/performance/profile-image.png";

//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { RevealImage } from "../../../Hooks/RevealImage";

function DataDriven() {
  const starsStyle = {
    bottom: "5%",
    left: "0%",
    width: "200px",
    zIndex: 200,
    position: "absolute",
  };

  const searchStyle = {
    top: "10%",
    right: "20%",
    width: "100px",
    position: "absolute",
    zIndex: 2,
  };

  const bgCircle = {
    borderRadius: "25em",
    width: "300px",
    height: "300px",
    position: "absolute",
    left: 0,
    top: 0,
  };
  const googleStyle = {
    top: "5%",
    left: "20%",
    minWidth: "180px",
    width: "30%",
    position: "absolute",
    zIndex: 2,
  };

  const rankingStyle = {
    top: "-10%",
    right: "-5%",
    width: "200px",
    position: "absolute",
    zIndex: 2,
  };

  const optimisationBGStyle = {
    width: "80%",
  };

  return (
    <div>
      <div className="relative bg-white text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:pt-20 lg:pb-8">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0">
              <div className="relative">
                <div class="bg-primary" style={bgCircle}></div>
                {/* <div style={searchStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="" src={search} />
                  </AnimatedAsset>
                </div> */}

                {/* <div style={googleStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={10}
                    minusx={-10}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={google} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div> */}

                <ScrollEffect speed={18} YFrom={8} YTo={-2}>
                  <Reveal>
                    <img
                      style={optimisationBGStyle}
                      className="p-2 mx-auto right-0 rounded-3xl overflow-hidden"
                      src={optimisationBG}
                    />
                  </Reveal>
                </ScrollEffect>
                <ScrollEffect speed={18} YFrom={-40} YTo={-15}>
                  <div style={starsStyle}>
                    <AnimatedAsset
                      duration={25000}
                      rotate={0}
                      minusrotate={0}
                      posx={-20}
                      minusx={-10}
                      posy={0}
                      minusy={0}
                    >
                      <Reveal>
                        <img className="" src={rating} />
                      </Reveal>
                    </AnimatedAsset>
                  </div>
                </ScrollEffect>
              </div>
            </div>
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <RevealImage>
                <h3 class="leading-tight  font-heading relative z-10 mb-8">
                  Data-Driven Optimization in Action
                </h3>
              </RevealImage>
              <Reveal>
                <p>
                  At Plain Design Studio, we believe in harnessing the power of
                  data to drive meaningful results for your performance
                  marketing campaigns. Our approach to data-driven optimization
                  begins with a commitment to continuous monitoring and
                  analysis. We meticulously track key performance metrics across
                  all channels, from click-through rates to conversion rates, to
                  uncover actionable insights that inform our strategy.
                </p>
              </Reveal>
              <Reveal>
                <p class="mb-8 relative z-10">
                  With real-time data at our fingertips, we're able to make
                  agile, informed decisions to optimize your campaigns on the
                  fly. Whether it's adjusting bidding strategies, refining
                  audience targeting, or testing new ad creatives, we stay
                  responsive to evolving trends and user behavior. This
                  iterative approach to optimization ensures that your campaigns
                  are always adapting and evolving to deliver maximum impact and
                  ROI.
                </p>
              </Reveal>
              <Reveal>
                <p class="mb-8 relative z-10">
                  But data-driven optimization goes beyond just reacting to
                  current performance—it's about anticipating future trends and
                  opportunities. Through predictive analytics and forecasting,
                  we're able to anticipate shifts in the market landscape and
                  proactively adjust our strategies to capitalize on emerging
                  opportunities. By staying one step ahead of the competition,
                  we help ensure that your campaigns are always poised for
                  success in an ever-changing digital landscape.
                </p>
              </Reveal>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DataDriven;
