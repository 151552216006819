import React from "react";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import Solution from "./Solution";
import Overview from "./Overview";
import ScrollBlur from "../../../Hooks/ScrollBlur";
import { Helmet } from "react-helmet";
import { RevealImage } from "../../../Hooks/RevealImage";
import { Reveal } from "../../../Hooks/Reveal";

function PostContent(props) {
  const { title, content, featured_media, id, acf } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="pt-32 flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post
  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }

  const bannerText = useParallax({
    speed: -10,
  });

  return (
    <div>
      <Helmet>
        <title>Plain Design Studio {title.rendered}</title>
        <meta name="description" content={acf.overview} />
        <meta property="og:type" key="og:type" content="article" />
        <meta property="og:image" content={`${FeaturedImage}`} />
      </Helmet>
      <div className="container-fluid top-0 relative bg-dark z-8 min-h-screen">
        <div className="relative col-8 min-h-screen">
          <ScrollBlur>
            <div
              className="w-100 top-0 backgroundImage absolute"
              style={{
                backgroundImage: `url(${FeaturedImage})`,
                height: "100vh",
              }}
            ></div>
          </ScrollBlur>
          <div className="w-100 top-0 backgroundImage absolute bg-gradient-to-t from-dark min-h-screen"></div>
          <div className="w-100 relative" style={{ height: "75vh" }}>
            <div
              class="flex justify-center items-center"
              style={{ height: "75vh", minHeight: "280px" }}
            >
              <div ref={bannerText.ref} class="pt-32 text-center">
                <RevealImage>
                  <h1 class="lg:text-5xl text-white">{title.rendered}</h1>
                </RevealImage>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white relative z-10 pt-0">
        <Overview class="bg-blue relative z-10" acf={acf} />
        <Solution class="bg-blue relative z-10" acf={acf} />

        <div class="full_width_wp ">
          <div dangerouslySetInnerHTML={{ __html: content.rendered }}></div>
        </div>
      </div>
    </div>
  );
}

export default PostContent;
