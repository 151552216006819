import React, { useRef } from "react";
import { default as thumbs } from "../../images/assets/thumbs.svg";
import { default as cat } from "../../images/assets/cat.svg";
import { default as coin } from "../../images/assets/coin.svg";
import { default as computer } from "../../images/assets/computer.svg";
import { default as lineTop } from "../../images/assets/lineTop.svg";
import { default as plainLogoMeta } from "../../images/logo/plain-logo.png";
import { default as topBanner } from "../../images/assets/top-banner.jpg";
import { Reveal } from "../../../Hooks/Reveal";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import "../../css/assets.css";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { RevealText } from "../../../Hooks/RevealText";
import { RevealImage } from "../../../Hooks/RevealImage";
import { Helmet } from "react-helmet";
import { default as success } from "../../images/assets/primary/sticker.svg";

//import { ParallaxProvider } from 'react-scroll-parallax';

const bgTopImageWrap = {
  height: "100%",
  minHeight: "300px",
};

const stickerImage = {
  left: "-10%",
  top: "-5%",
  maxWidth: "200px",
  width: "35%",
  margin: "0px",
  position: "absolute",
};

const catStyles = {
  top: "10%",
  right: "5%",
  maxWidth: "220px",
  minWidth: "180px",
  width: "25%",
};

const thumbStyles = {
  bottom: "15%",
  left: "-5%",
  width: "25%",
  maxWidth: "220px",
  minWidth: "180px",
};

const coinStyles = {
  top: "10%",
  left: "5%",
  width: "20%",
  maxWidth: "220px",
  minWidth: "180px",
};

const computerStyles = {
  position: "absolute",
  width: "40%",
  maxWidth: "350px",
  bottom: "-300px",
  right: "-20px",
};

const computerTopStyle = {
  top: "-10px",
  left: "0%",
  width: "30%",
  maxWidth: "250px",
};

function TopBanner() {
  const SVGTop = useParallax({
    speed: 20,
    startScroll: -280,
    endScroll: 280,
    //easing: 'easeInCirc',
  });
  //const topBannerRef = useRef();
  return (
    <div>
      <Helmet>
        <title>
          Plain Design -Digital Design, Website & Marketing, Melbourne
        </title>
        <meta
          name="description"
          content="Plain Design Studio provides the bespoke and cost effective digital design, website development & digital marketing services headquarted in Melbourne"
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" content={`${plainLogoMeta}`} />
      </Helmet>

      <div
        // ref={topBannerRef}
        className="w-100 overflow-clip bg-light"
      >
        <div className="relative top-0 w-full xl:py-40 md:py-40 py-24 min-h-screen content-center">
          <img
            ref={SVGTop.ref}
            className="absolute top-0 right-0 topLineStyle  hidden md:block"
            src={lineTop}
            alt=""
          />

          <div className="relative lg:w-11/12 w-12/12  mx-auto flex md:flex-nowrap flex-wrap items-center">
            <div className="w-100 mx-auto lg:w-8/12 xl:w-6/12 md:w-5/12 w-12/12 relative">
              {/* <ScrollEffect rotateFrom={-10} rotateTo={20} XFrom={50} XTo={-20}>
                <div style={computerTopStyle} className="absolute">
                  <AnimatedAsset
                    duration={13000}
                    rotate={15}
                    minusrotate={-25}
                    posx={-16}
                    minusx={22}
                    posy={30}
                    minusy={-40}
                  >
                    <img className="" alt="Cartoon Coin mascot" src={coin} />
                  </AnimatedAsset>
                </div>
              </ScrollEffect> */}

              <div className="relative text-dark">
                {/* <div style={computerStyles} className="absolute">
                  <ScrollEffect
                    speed={-25}
                    rotateFrom={-10}
                    rotateTo={20}
                    XFrom={-50}
                    XTo={100}
                  >
                    <AnimatedAsset
                      duration={12300}
                      rotate={25}
                      minusrotate={-35}
                      posx={-55}
                      minusx={20}
                      posy={12}
                      minusy={-25}
                    >
                      <img
                        alt="web development computer image"
                        src={computer}
                      />
                    </AnimatedAsset>
                  </ScrollEffect>
                </div> */}

                <div className="relative mx-auto md:bg-transparent p-8 md:pb-0 md:text-left text-center">
                  <ScrollEffect speed={4}>
                    <RevealText>
                      <p class="uppercase text-blue mb-0">
                        Where Creativity Meets Strategy
                      </p>
                    </RevealText>
                    <RevealText delay="0.3">
                      <h1 className="relativez-50  mt-0 text-3xl text-dark md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl leading-tight font-heading">
                        Boosting Melbourne Business with Creative Marketing &
                        Design
                      </h1>
                    </RevealText>

                    <RevealText delay="0.6">
                      <p class="xl:w-10/12 w-12/12 mb-2 relative z-10 ">
                        Unlock your Melbourne business’s potential with our
                        creative marketing and design solutions. We blend
                        innovative strategies with eye-catching design to fuel
                        your growth and make your brand stand out.
                      </p>
                    </RevealText>

                    <Link to="/services">
                      <RevealText delay="0.9">
                        <button class="text-light bg-primary mt-2 mb-2 ms-1 px-6 pr-8 py-1 text-sm  group inline-block fw-900 leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                          Our Services
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </svg>
                        </button>
                      </RevealText>
                    </Link>
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div className="w-12/12 lg:w-12/12 xl:w-6/12 md:w-8/12 relative mx-auto bgTopImageWrap pt-8 md:pt-12">
              <ScrollEffect speed={-5} fromWidth={0.9} toWidth={1.02}>
                <Reveal>
                  <img
                    alt="Team designing a website"
                    className="rounded-xl top-0 mx-auto"
                    style={{ minHeight: "100px", height: "auto" }}
                    src={topBanner}
                  />
                </Reveal>
                <div style={stickerImage}>
                  <ScrollEffect
                    className=" w-100"
                    speed={1}
                    rotateFrom={30}
                    rotateTo={30}
                  >
                    <AnimatedAsset
                      duration={12300}
                      rotate={25}
                      minusrotate={-35}
                      posx={0}
                      minusx={0}
                      posy={0}
                      minusy={0}
                    >
                      <img alt="Thumb illustration" src={success} />
                    </AnimatedAsset>
                  </ScrollEffect>
                </div>
              </ScrollEffect>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
