import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import { default as thumbs } from "../images/assets/primary/thumbs.svg";
import { default as cat } from "../images/assets/primary/cat.svg";
import { default as coin } from "../images/assets/primary/coin.svg";
import { default as computer } from "../images/assets/primary/computer.svg";
import { default as lineBottom } from "../images/assets/lineBottom.svg";
import { default as lineTop } from "../images/assets/lineTop.svg";
import useMeasure from "react-use-measure";

function Menu(props) {
  const [refHeight, height] = useMeasure();

  // var elHeight = height['height']
  // console.log(elHeight);
  const [subMenuClicked, setSubMenuClicked] = useState(0);

  const toggleIsClicked = () => {
    // 👇️ passed function to setState
    setSubMenuClicked((current) => !current);
    console.log(subMenuClicked);
  };

  const { showSubMenu } = useSpring({
    showSubMenu: subMenuClicked ? 1 : 0,
    from: {
      maxHeight: "0px",
    },
    config: { mass: 5, tension: 2000, friction: 200 },
  });

  return (
    <ul class="list-none bold flex text-2lg flex-col md:flex-row m-2  md:font-bold">
      <li className="menulink">
        <Link to="/case-studies/page/1" onClick={props.closeMenu}>
          Case Studies
        </Link>
      </li>

      <li className="menulink">
        <Link to="/services" onClick={props.closeMenu}>
          <button onMouseEnter={() => toggleIsClicked(true)}>Services</button>
        </Link>
        <animated.div
          onMouseLeave={() => toggleIsClicked(false)}
          ref={refHeight}
          className={subMenuClicked ? "shadow-lg sub-menu" : "sub-menu"}
          style={{
            maxHeight: showSubMenu.to({
              range: [0, 1],
              output: ["0px", "400px"],
            }),
            color: showSubMenu.to({
              range: [0, 1],
              output: ["black", "white"],
            }),
            display: "flex",
          }}
        >
          <ul class="text-sm">
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/website-design"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={computer}
                /> */}
                Website Design
              </Link>
            </li>
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/content-marketing-graphic-design"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={thumbs}
                /> */}
                Graphic Design
              </Link>{" "}
            </li>
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/branding-marketing"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={cat}
                /> */}
                Branding
              </Link>{" "}
            </li>
          </ul>
          <ul class="text-sm">
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/SEO-search-engine-optimisation"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={coin}
                /> */}
                Website SEO
              </Link>{" "}
            </li>
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/ecommerce-website"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={computer}
                /> */}
                Ecommerce Website
              </Link>{" "}
            </li>
            <li class="hover:bg-light rounded-lg">
              <Link
                to="/services/performance-marketing-optimisation"
                onClick={props.closeMenu}
                className="subMenuLink hover:transition-all hover:translate-x-1 group"
              >
                {/* <img
                  className="menu-icon group-hover:scale-150 transition transition-500"
                  src={computer}
                /> */}
                Performance Marketing
              </Link>{" "}
            </li>
          </ul>
        </animated.div>
      </li>

      <li className="menulink">
        <Link to="/about" className="" onClick={props.closeMenu}>
          About
        </Link>
      </li>
      <li className="menulink">
        <Link
          aria-label="posts link for articles"
          alt-tag="posts link for articles"
          to="/posts/page/1/1"
          className=""
          onClick={props.closeMenu}
        >
          Posts
        </Link>
      </li>
      {/* <li className="menulink">
        <Link to="/contact" className="" onClick={props.closeMenu}>
          Get in Touch
        </Link>
      </li> */}
    </ul>
  );
}

export default Menu;
