import React from "react";
import { default as coin } from "../../images/assets/coin.svg";
import { default as topBanner } from "../../images/eccommerce/ecommerceBanner.jpg";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { default as wordpressLogo } from "../../images/logo/images/wordpress.svg";
//wooCommerce
import { Helmet } from "react-helmet";
import { default as wooCommerce } from "../../images/logo/images/wooCommerce.svg";
import { default as shopify } from "../../images/logo/images/shopify.svg";
//import { ParallaxProvider } from 'react-scroll-parallax';

const computerTopStyle = {
  top: "30px",
  left: "0%",
  width: "30%",
  maxWidth: "250px",
};

function EccommerceTopBanner() {
  return (
    <div>
      <Helmet>
        <title>
          Experts in WooCommerce, Shopify & Magento eCommerce website
        </title>
        <meta
          name="description"
          content="We set up user friendly ecommerce websites via WooCommerce, Shopify or Magento platforms for you and customise the UI/UX to maximize the conversion"
        />
      </Helmet>
      <div className="w-full overflow-clip bg-light">
        <div className="relative top-0 bg-light w-full md:py-16 py-28">
          <div className="relative container flex flex-wrap">
            <div className="w-100  lg:w-6/12 xl:w-6/12 relative">
              <ScrollEffect rotateFrom={-10} rotateTo={20} XFrom={50} XTo={-20}>
                <div
                  style={computerTopStyle}
                  className="absolute hidden md:block"
                >
                  <AnimatedAsset
                    duration={18000}
                    rotate={-25}
                    minusrotate={-5}
                    posx={-16}
                    minusx={22}
                    posy={10}
                    minusy={5}
                  >
                    <img className="" alt="Cartoon Coin mascot" src={coin} />
                  </AnimatedAsset>
                </div>
              </ScrollEffect>

              <div className="relative text-dark md:mb-24 md:mb-8">
                <div className="md:bg-transparent p-8 md:pb-0 md:mt-12 lg:mt-24">
                  <p className="text-primary text-sm mb-0 font-bold">
                    Get That Cash Flowing
                  </p>
                  <h1 className="relativez-50 lg:text-left text-3xl text-dark md:text-3xl lg:text-4xl xl:text-4xl leading-tight font-heading">
                    Ecommerce Websites
                  </h1>
                  <p>
                    The rise of Ecommerce websites shows no sign of slowing down
                    and selling your products and services online has never been
                    easier.
                  </p>
                  <div className="flex">
                    <img
                      className="mr-8"
                      style={{ height: "40px" }}
                      src={wordpressLogo}
                    ></img>
                    <img
                      className="mr-8"
                      style={{ height: "40px" }}
                      src={wooCommerce}
                    ></img>
                    <img
                      className="mr-8"
                      style={{ height: "40px" }}
                      src={shopify}
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-11/12 lg:w-6/12 xl:w-6/12 relative mx-auto bgTopImageWrap mt-24 md:mt-28 lg:mt-16">
              <ScrollEffect speed={-10}>
                <img
                  alt="Team designing a website"
                  className="rounded-xl top-0 mx-auto"
                  style={{ minHeight: "100%", height: "auto" }}
                  src={topBanner}
                />
              </ScrollEffect>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EccommerceTopBanner;
