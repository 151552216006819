import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as rocket } from "../../images/assets/rocket.svg";
import { default as websiteImage } from "../../images/assets/computer-web-design.jpg";
import { Link } from "react-router-dom";

function WebsiteBranding() {
  const iconStyles = {
    right: "2%",
    width: "15%",
    maxWidth: "250px",
    minWidth: "80px",
    bottom: "0%",
    position: "absolute",
    opacity: 1,
  };

  return (
    <div>
      <div className="relative bg-light">
        <section className="container mx-auto">
          <div style={iconStyles}></div>
          <div className="">
            <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
              <h3 class="text-2xl text-blue">
                <span className="toplinesSvg">Get</span> the right customers
              </h3>
              <Reveal>
                <div className="grid grid-cols-12 gap-8">
                  <div className="md:col-span-6 lg:col-span-6 col-span-12 mx-auto">
                    <p class="mb-8 relative z-10">
                      If branding and graphic design represents your business;
                      your website is how you pitch your company to any
                      visitors. Your website is an extension of you and your
                      company, it is an opportunity to communicate with anyone
                      in the world. Websites are a powerful tool that increase
                      your range and connect with people on a scale which is
                      otherwise impossible. Cheap self build websites may have
                      the opposite effect.
                    </p>
                  </div>
                  <div className="md:col-span-6 lg:col-span-6 col-span-12 mx-auto">
                    <p class="mb-8 relative z-10">
                      Your website is an opportunity to send a clear message to
                      your customers and design and layout is an important
                      aspect of that. A well designed website is a message well
                      received and instills a level of trust in the customer.
                      The site is a reflection of your company, so it is vital
                      it represents you at your best! This is super import for{" "}
                      <Link to={"/services/ecommerce-website"}>
                        online ecommerce stores
                      </Link>
                    </p>
                  </div>
                </div>
              </Reveal>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default WebsiteBranding;
