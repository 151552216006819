import React from "react";
import TopBanner from "../TopBanner";
import BrandingIntro from "../views/Branding/BrandingIntro";
import { BrandingImageBanner } from "../views/Branding/BrandingImageBanner";
import { BrandingAudience } from "../views/Branding/BrandingAudience";

function Branding() {
  return (
    <div>
      <TopBanner
        metaHeader="Company Branding in Melbourne"
        header="Company Branding"
        topHeader="Logo design & Brand Guidelines"
        metaDescription="Each and every customer will have an instant reaction to your business, our job is to utilise that snap judgement to speak to your target audience. Branding is what people think about you, lets convert those thoughts to clients, customers, sales & success! We use a multitude of mediums to achieve the correct perception of your brand. Without a strategic set of brand guidelines for your business, customers will make up their own minds about you and your company."
      />
      <BrandingIntro />
      <BrandingImageBanner />
      <BrandingAudience />
    </div>
  );
}

export default Branding;
