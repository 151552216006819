import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as webPageShop } from "../../images/eccommerce/web-page-shop.png";
import { default as arrowCircle } from "../../images/eccommerce/arrows.png";
import { default as mobileShop } from "../../images/eccommerce/mobile-shop.png";
import { default as starRating } from "../../images/eccommerce/star-rating.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function ContentWriting() {
  const iconStyles = {
    left: "0%",
    width: "5%",
    maxWidth: "350px",
    minWidth: "280px",
    top: "0%",
    opacity: "0.05",
    position: "absolute",
  };

  const arrowStyle = {
    bottom: "10%",
    left: "20px",
    width: "120px",
    position: "absolute",
    zIndex: 2,
  };

  const webPageStyle = {
    bottom: "30%",
    width: "100%",
    minWidth: "220px",
    height: "auto",
    left: "-100%",
    position: "absolute",
  };

  const ratingStyle = {
    bottom: "35%",
    left: "20px",
    width: "220px",
    position: "absolute",
    zIndex: 2,
  };

  const mobileShopStyle = {
    bottom: "0px",
    right: "5%",
    width: "30%",
    minWidth: "220px",
    position: "absolute",
  };

  return (
    <div>
      <div className="relative bg-content text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="lg:col-span-12 md:col-span-12 md:col-start-2 col-span-12">
              <h3 class="leading-tight  font-heading relative z-10 mb-2">
                Content and copywriting for SEO
              </h3>
            </div>
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <p>
                Since Google (and therefore other search engines) place such
                high value on quality content, it’s essential that the content
                that appears on your site is well researched and written with
                SEO in mind.
              </p>
              <p class="mb-8 relative z-10">
                Whether it’s your landing pages, service pages or blog, good
                keyword research is the foundation on which the content will be
                formed.
              </p>
            </div>

            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <p>
                Be careful though, simply throwing in the same keyword over and
                over again (known as keyword stuffing) will be punished by
                Google so it’s imperative that you use them sparingly and
                include more related words - words that go well with the
                keywords that were researched. When Google crawls your content,
                it will look for these related words to be sure of what the
                content is about.
              </p>
              <p class="mb-8 relative z-10">
                Our copywriting team is highly skilled and experienced in
                delivering engaging content that Google loves as much as our
                clients do.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContentWriting;
