import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { Link } from "react-router-dom";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";

function AboutIntro() {
  return (
    <div>
      <ChangeBGColour>
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                A unique blend of digital expertise and aesthetic vision
              </h4>

              <div className="grid grid-cols-12 absolute gap-8 top-0">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img className="w-100 opacity-10" src={thumb} />
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div class="md:col-span-7 col-span-12 z-10">
              <p class="relative z-10">
                We focus on creating bespoke work, specifically designed to meet
                your need. That need may be{" "}
                <Link
                  className="underline group-hover:scale-150 transition transition-500"
                  onClick={() => {}}
                  to={`/services/branding-marketing`}
                >
                  brand awareness
                </Link>
                , product promotion or{" "}
                <Link
                  className="underline group-hover:scale-150 transition transition-500"
                  onClick={() => {}}
                  to={`/services/website-design`}
                >
                  developing a new website
                </Link>
                , whatever it is, we do it with passion.{" "}
              </p>
              <p class="relative z-10">
                We are here to stay and we’ll never do just enough. We strive to
                do the very best. Great work is ingrained in our philosophy.{" "}
                <Link
                  className="underline group-hover:scale-150 transition transition-500"
                  onClick={() => {}}
                  to={`/services/content-marketing-graphic-design`}
                >
                  Great design
                </Link>{" "}
                benefits you, and great client relations benefit us. Striving
                for long term and meaningful business relationships is one of
                our core business values. Our success as marketing agency is
                defined by our joint successes with all our clients. We are
                happy to go the extra mile in taking care of your marketing and
                join you on your journey to grow your business.{" "}
              </p>
              <p class="mb-8 relative z-10">
                With our knack for graphic design, branding, and marketing,
                paired with a deep dive into web development, we offer a unique
                blend of coding expertise and aesthetic vision. Whether you need
                a striking website or a memorable brand identity, we are your
                go-to for all things digital and design!
              </p>
            </div>
          </div>
        </section>
      </ChangeBGColour>
    </div>
  );
}

export default AboutIntro;
