import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
export function ScrollBlur(props) {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 0,
  }));

  const parallaxLevel = 1;
  if (scrollY < 40) {
    springsetScrollY({ springscrollY: scrollY });
  }

  const interpHeader = springscrollY.interpolate(
    (o) => `blur(${o / parallaxLevel / 2}px)`
  );

  return (
    <animated.div style={{ filter: interpHeader }}>
      {props.children}
    </animated.div>
  );
}

export default ScrollBlur;
