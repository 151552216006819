import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { Link } from "react-router-dom";

function GraphicsBranding() {
  const iconStyles = {
    right: "2%",
    width: "15%",
    maxWidth: "250px",
    minWidth: "80px",
    bottom: "0%",
    position: "absolute",
    opacity: 1,
  };

  return (
    <div>
      <div className="relative bg-light">
        <section className="container mx-auto">
          <div style={iconStyles}></div>
          <div className="">
            <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
              <h3 class="text-2xl text-blue">
                <span className="toplinesSvg">More</span> than just pretty
                pictures
              </h3>
              <Reveal>
                <div className="grid grid-cols-12 gap-8">
                  <div className="md:col-span-6 lg:col-span-6 col-span-12 mx-auto">
                    <p class="mb-8 relative z-10">
                      Our first stage is to consider how we’ll connect to new a
                      audience alongside re-engaging your existing customers.
                    </p>
                    <p class="mb-8 relative z-10">
                      Good quality content alone is not enough to result in
                      sales. Consistency is key! Instead, it is content with a
                      vision, strategy and a strong cohesive message that builds
                      engagement. This grows a strong connection to your
                      companies brand values and leads to success digital
                      marketing campaign.
                    </p>
                    <p class="mb-8 relative z-10">
                      Identifying your target audience is the essential first
                      step in achieving successful results.Be specific.
                    </p>
                  </div>
                  <div className="md:col-span-6 lg:col-span-6 col-span-12 mx-auto">
                    <p class="mb-8 relative z-10">
                      Social media often feels like the singular option in a
                      digital campaign. However, we know better. By utilising
                      many mediums including email marketing, podcasts,
                      tutorials, videos and more we are able to ensure your
                      voice is on the right platform to reach the right
                      audience. The correct strategy will allow you to grow
                      customer engagement and convert this into leads and direct
                      sales.{" "}
                    </p>

                    <p class="mb-8 relative z-10">
                      Marketing costs time, money & effort. Don’t waste your
                      resources talking to the wrong demographic. Let us help
                      you to tailor your specific marketing campaign considering
                      your preferred audience, building an emotional connection
                      and solidifying{" "}
                      <Link
                        className="text-light underline group-hover:scale-150 transition transition-500"
                        onClick={() => {}}
                        to={`/services/branding-marketing`}
                      >
                        brand loyalty
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </Reveal>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default GraphicsBranding;
