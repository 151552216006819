import React from "react";
import TopBanner from "../TopBanner";
import SEOTopBanner from "../views/SEO/SEOTopBanner";
import SEOIntro from "../views/SEO/SEOIntro";
import OptimisationSEO from "../views/SEO/OptimisationSEO";
import SEOBusiness from "../views/SEO/SEOBusiness";
import SEOCTA from "../views/SEO/SEOCTA";
import SEOOnPage from "../views/SEO/SEOOnPage";
import ContentWriting from "../views/SEO/ContentWriting";

function Ecommerce() {
  return (
    <div>
      <SEOTopBanner />
      <SEOIntro />
      <OptimisationSEO />
      <SEOBusiness />
      <SEOCTA />
      <SEOOnPage />
      <ContentWriting />
    </div>
  );
}

export default Ecommerce;
