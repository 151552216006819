import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";

function Values() {
  return (
    <div>
      <div className="relative p-0 md:p-12">
        <section className="container py-8 z-10 md:py-12 lg:py-12">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-8 lg:col-start-3 md:col-start-1 md:col-span-12 sm:col-span-12 xl:col-start-3 xl:col-span-8 xs:col-span-12 mb-8">
              <ScrollEffect className="top-0" speed={-2} XFrom={10} XTo={-3}>
                <div className="p-8 text-dark rounded-xl m-auto ">
                  <h5 className="text-primary font-weight-bold mb-0 text-sm">
                    Around for the long term
                  </h5>
                  <h5 class="text-2xl lg:text-xl leading-tight font-heading relative z-10">
                    We are your friends
                  </h5>
                  <p class="relative z-10">
                    When we begin working with you, we see you as an extension
                    of our company. We want you to become successful, after all
                    your success is our success. To achieve this we want to open
                    up a dialogue with you, and if we see something related to
                    your industry will will let you know. We view it as a
                    collaboration.
                  </p>
                </div>
              </ScrollEffect>
            </div>

            <div className="col-span-12 lg:col-span-8 lg:col-start-3 md:col-start-1 md:col-span-10 sm:col-span-12 xl:col-start-3 xl:col-span-8 xs:col-span-12 mb-16">
              <ScrollEffect className="top-0" speed={-2} XFrom={-3} XTo={0}>
                <div className="p-8 rounded-xl m-auto">
                  <h5 className="text-primary font-weight-bold mb-0 text-sm">
                    Problem solving starts with the truth
                  </h5>
                  <h5 class="text-2xl lg:text-xl leading-tight font-heading relative z-10">
                    No nonsense approach
                  </h5>
                  <p class="relative z-10">
                    We will always tell you the truth about your project.
                    Straight talking may not be everyone’s style, but we think
                    it’s important not to waste time avoiding tough
                    conversations. The truth may hurt, but the sooner it is
                    realised, the quicker we can move onwards and upwards.
                  </p>
                </div>
              </ScrollEffect>
            </div>

            <div className="col-span-12 lg:col-span-8 lg:col-start-3 md:col-start-1 md:col-span-10 sm:col-span-12 xl:col-start-3 xl:col-span-8 xs:col-span-12 mb-16">
              <ScrollEffect className="top-0" speed={-2} XFrom={15} XTo={-3}>
                <div className="p-8 rounded-xl m-auto ">
                  <h5 className="text-primary font-weight-bold mb-0 text-sm">
                    Driving a successful partnership
                  </h5>
                  <h5 class="text-2xl lg:text-xl leading-tight font-heading relative z-10">
                    We work with passionate people
                  </h5>
                  <p class="relative z-10">
                    Creating a brand and marketing materials for a company can
                    be a really fun and exciting experience, but there’s a lot
                    of analytics behind that eye catching logo! Nothing gives us
                    greater pleasure than seeing someone’s business grow as a
                    result of our help but it does often require a collaborative
                    approach and a whole team effort.
                  </p>
                </div>
              </ScrollEffect>
            </div>

            <div className="col-span-12 lg:col-span-8 lg:col-start-3 md:col-start-1 md:col-span-10 sm:col-span-12 xl:col-start-3 xl:col-span-8 xs:col-span-12 mb-16">
              <ScrollEffect className="top-0" speed={-2} XFrom={-3} XTo={10}>
                <div className="p-8 text-dark rounded-xl m-auto mb-5">
                  <h5 className="text-primary font-weight-bold mb-0 text-sm">
                    Help us help you
                  </h5>
                  <h5 class="text-2xl lg:text-3xl leading-tight font-heading relative z-10">
                    Keep us in the loop
                  </h5>
                  <p class="relative z-10">
                    We will become the marketing department of your company
                    which will take a huge amount of stress out of your
                    business. If you have a plan to sell a certain product or
                    promote a new aspect of your business don’t hesitate to call
                    us- we’re pretty certain we can help!
                  </p>
                </div>
              </ScrollEffect>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Values;
