import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as seventeenK } from "../../images/SEO/17k.png";
import { default as businessBg } from "../../images/SEO/businessBg.png";
import { default as pieChart } from "../../images/SEO/pieChart.png";
import { default as graph } from "../../images/SEO/graph.png";
import { default as starRating } from "../../images/eccommerce/star-rating.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function SEOBusiness() {
  const pieChartStyle = {
    top: "0%",
    left: "0%",
    width: "40%",
    position: "absolute",
    zIndex: 2,
  };

  const graphStyle = {
    top: "0%",
    right: "0%",
    width: "50%",
    position: "absolute",
    zIndex: 2,
  };

  const seventeenKStyle = {
    bottom: "10%",
    right: "0%",
    width: "180px",
    position: "absolute",
    zIndex: 2,
  };

  const businessBgStyle = {
    width: "100%",
    left: "0px",
    marginRight: "50%",
  };

  return (
    <div>
      <div className="relative bg-light text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:pt-20 lg:pb-8">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <h3 class="leading-tight  font-heading relative z-10 mb-8">
                Is SEO right for your business?
              </h3>
              <p>
                With over 85% of all consumers using search engines to find and
                purchase services and products, SEO is not only right for you,
                but essential.
              </p>
              <p class="mb-8 relative z-10">
                It is, however, important to understand that SEO is not a quick
                fix and will require a long term commitment. With that in mind,
                you might think you’d be better off with PPC (pay-per-click)
                advertising; after all, ads can appear on page one of Google,
                however most people will ignore an ad and click on the organic
                results.
              </p>
              <p class="mb-8 relative z-10">
                SEO doesn’t go away when you stop paying for ad space. If you
                rank for target keywords, you will reach more people and keep a
                constant flow of traffic visiting your site.
              </p>
              <p class="mb-8 relative z-10">
                We aren’t saying you shouldn’t use PPC, in fact if they’re used
                together effectively, PPC and SEO are perfect companions that
                can significantly improve the visibility of your business,
                leading to more awareness and, ultimately, more revenue.{" "}
              </p>
            </div>

            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0 order-first lg:order-last">
              <div className="relative">
                <div style={pieChartStyle}>
                  <AnimatedAsset
                    duration={20000}
                    rotate={-20}
                    minusrotate={20}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <img className="" src={pieChart} />
                  </AnimatedAsset>
                </div>

                <div style={seventeenKStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={seventeenK} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <div style={graphStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect speed={2}>
                      <img src={graph} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect speed={8}>
                  <img
                    style={businessBgStyle}
                    className="p-2 mx-auto right-0"
                    src={businessBg}
                  />
                </ScrollEffect>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SEOBusiness;
