import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import { default as thumbs } from "../images/assets/primary/thumbs.svg";
import { default as cat } from "../images/assets/primary/cat.svg";
import { default as coin } from "../images/assets/primary/coin.svg";
import { default as computer } from "../images/assets/primary/computer.svg";
import { default as lineBottom } from "../images/assets/lineBottom.svg";
import { default as lineTop } from "../images/assets/lineTop.svg";
import useMeasure from "react-use-measure";
import "../css/menu.css";

function MobileMenu(props) {
  return (
    <ul class="list-none bold flex text-2lg flex-col md:flex-row m-2 text-white md:font-bold">
      <li className="menulink">
        <Link
          to="/case-studies/page/1"
          onClick={props.closeMenu}
          className="text-white hover:transition-all hover:translate-x-1 group"
        >
          Case Studies
        </Link>
      </li>

      <li className="menulink">
        <Link to="/services" onClick={props.closeMenu}>
          Services
        </Link>
      </li>
      <li className="menulink">
        <Link to="/about" className="" onClick={props.closeMenu}>
          About
        </Link>
      </li>
      <li className="menulink">
        <Link
          aria-label="posts link for articles"
          alt-tag="posts link for articles"
          to="/posts/page/1/1"
          className=""
          onClick={props.closeMenu}
        >
          Posts
        </Link>
      </li>
      {/* <li className="menulink">
        <Link to="/contact" className="" onClick={props.closeMenu}>
          Get in Touch
        </Link>
      </li> */}
    </ul>
  );
}

export default MobileMenu;
