import React, { useState } from "react";
import PostCard from "./PostCard";
import { Reveal } from "../../../Hooks/Reveal";

const Pagination = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div class="container">
      <div class="grid grid-cols-12 gap-4">
        {currentItems.map((post) => (
          <div className="col-span-12 md:col-span-6" key={post.id}>
            <PostCard post={post} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pagination;
