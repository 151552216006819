import React, { Component, useState } from "react";

export class FormServices extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    let nextButton;

    //if () {

    nextButton = (
      <button
        onClick={this.continue}
        class="text-dark group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
      >
        Final Step
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </button>
    );
    //}

    return (
      <div className="w-full">
        <h5 className="text-md">How can we help?</h5>
        <div class="flex justify-center">
          <div className="w-full">
            <div class="form-floating mb-3 w-full">
              <div class="mb-3 w-100">
                <textarea
                  type="servic"
                  onChange={handleChange("service")}
                  defaultValue={values.service}
                  class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="What services do you need"
                ></textarea>
              </div>

              <div class="flex space-x-5 justify-between">
                <button
                  onClick={this.back}
                  class="group inline-block  bg-none py-2 text-primary text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-6 w-6 group-hover:translate-x-2 transition delay-100 transition-200"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>

                {nextButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormServices;
