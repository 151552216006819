import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as eccomBg } from "../../images/eccommerce/eccom-bg.png";
import { default as rise } from "../../images/eccommerce/rise.png";
import { default as cost } from "../../images/eccommerce/cost.png";
import { default as dashboard } from "../../images/eccommerce/dashboard.png";
import { default as testimonial } from "../../images/eccommerce/testimonial.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";

function EccommerceSales() {
  const iconStyles = {
    left: "0%",
    width: "5%",
    maxWidth: "350px",
    minWidth: "280px",
    top: "0%",
    opacity: "0.05",
    position: "absolute",
  };

  const riseStyle = {
    top: "30%",
    left: "20px",
    width: "150px",
    position: "absolute",
    zIndex: 20,
  };
  const costStyle = {
    bottom: "20%",
    left: "10%",
    width: "150px",
    position: "absolute",
    zIndex: 20,
  };

  const webPageStyle = {
    bottom: "30%",
    width: "100%",
    minWidth: "220px",
    height: "auto",
    left: "-100%",
    position: "absolute",
  };

  const testimonialStyle = {
    top: "0%",
    left: "20px",
    width: "60%",
    position: "absolute",
    zIndex: 2,
  };

  const dashboardStyle = {
    bottom: "0px",
    right: "-8%",
    width: "80%",
    minWidth: "220px",
    position: "absolute",
  };

  return (
    <div>
      <div className="relative bg-white text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div class="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12">
              <h3 class="leading-tight font-heading relative z-10 mb-8">
                Increase sales and make payments easy{" "}
              </h3>
              <p>
                Even if you have a bricks and mortar store, going online is a
                no-brainer. You can still sell when your door is closed and
                you’ll give customers the opportunity to browse at their leisure
                and compare products, services and prices before they make a
                purchase decision.
              </p>
              <p class="mb-8 relative z-10">
                You’ll open your business up to countless opportunities for
                growth with fewer overheads and gather those all important
                reviews to keep people coming back.
              </p>

              <p class="mb-8 relative z-10">
                Add in a quick, secure way to take payments and it’s no wonder
                online shopping is going nowhere.
              </p>
            </div>

            <div className="lg:col-span-6 md:col-span-10 md:col-start-2 col-span-12 lg:mt-0 lg:px-8 order-first lg:order-last">
              <div className="relative">
                <div style={riseStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={20}
                    minusx={20}
                    posy={0}
                    minusy={0}
                  >
                    <img src={rise} />
                  </AnimatedAsset>
                </div>

                <div style={costStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={35}
                    minusx={30}
                    posy={0}
                    minusy={0}
                  >
                    <img src={cost} />
                  </AnimatedAsset>
                </div>

                <div style={testimonialStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={0}
                    minusx={0}
                    posy={0}
                    minusy={0}
                  >
                    <ScrollEffect XFrom={20} XTo={0} speed={0.5}>
                      <img src={testimonial} />
                    </ScrollEffect>
                  </AnimatedAsset>
                </div>

                <ScrollEffect YFrom={-8} YTo={8}>
                  <img className="w-100 p-2 mx-auto right-0" src={eccomBg} />
                </ScrollEffect>

                <div style={dashboardStyle}>
                  <ScrollEffect YFrom={-5} YTo={-20}>
                    <img
                      className="w-100 p-2 mx-auto right-0"
                      src={dashboard}
                    />
                  </ScrollEffect>
                </div>

                <div className="absolute" style={{ ...webPageStyle }}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default EccommerceSales;
