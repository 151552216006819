import React from "react";
import TopBanner from "../TopBanner";
import GraphicsIntro from "../views/GraphicDesign/GraphicsIntro";
import GraphicsBody from "../views/GraphicDesign/GraphicsBody";
import ServiceGraphics from "../views/GraphicDesign/ServiceGraphics";
import GraphicsBranding from "../views/GraphicDesign/GraphicsBranding";
import CarouselCases from "../views/CarouselCases";

function GraphicDesign() {
  return (
    <div>
      <TopBanner
        header="Graphic Design"
        topHeader="Graphic Design & Content Marketing"
        metaDescription="We pride ourselves on developing custom design materials tailored to your specific needs. We create one-of-a-kind graphic designs, specifically tailored to maximise customer conversion rates. Graphic design is an absolute must when developing all public facing products and materials such social media ads, posters and web design in order to evoke the right audience response and power your marketing!"
      />

      <GraphicsIntro />
      <ServiceGraphics />
      <GraphicsBody />
      <GraphicsBranding />
      <CarouselCases />
    </div>
  );
}

export default GraphicDesign;
