import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";

function GraphicsIntro() {
  return (
    <div>
      <ChangeToPrimary>
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <Reveal reveal={"h4"}>
                <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                  Make a statement with beautiful graphic design
                </h4>
              </Reveal>

              <div className="grid grid-cols-12 absolute gap-8 top-0">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img className="w-100 opacity-10" src={thumb} />
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div class="md:col-span-7 col-span-12 z-10">
              <Reveal>
                <p class="mb-8 relative z-10">
                  Is your marketing design material somewhat lacklustre? We have
                  the skill to marry your vision with an evidence-based design
                  approach to ensure we connect with your audience. Using colour
                  theory, layout and industry trends, we create tailor made
                  graphic design material to power your brand. Your graphic
                  design material is an important part of your overall brand, it
                  enables clear communication with your chosen audience.
                  Communication through visual means enables you to target
                  clients in a split second.
                </p>
              </Reveal>
              <Reveal>
                <p class="mb-8 relative z-10">
                  We pride ourselves on developing custom design materials
                  tailored to your specific needs. We create one-of-a-kind
                  graphic designs, specifically tailored to maximise customer
                  conversion rates. Graphic design is an absolute must when
                  developing all public facing products and materials such
                  social media ads, posters and web design in order to evoke the
                  right audience response and power your marketing!
                </p>
              </Reveal>
            </div>
          </div>
        </section>
      </ChangeToPrimary>
    </div>
  );
}

export default GraphicsIntro;
