import React from "react";
import { Reveal } from "../../../Hooks/Reveal";
import { default as thumb } from "../../images/assets/thumbs.svg";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { ChangeToPrimary } from "../../../Hooks/ChangeToPrimary";

function GraphicsIntro() {
  return (
    <div>
      <ChangeToPrimary>
        <section className="container relative mx-auto px-4 py-24 z-10 md:py-32 lg:py-32">
          <div className="grid grid-cols-12 gap-8">
            <div className="md:col-span-5 col-span-12  relative">
              <Reveal reveal={"h4"}>
                <h4 class="text-3xl mb-0 lg:text-3xl leading-tight font-heading relative z-10">
                  Connect to your customers on a personal level
                </h4>
              </Reveal>

              <div className="grid grid-cols-12 absolute gap-8 top-0">
                <div className="col-span-12">
                  <ScrollEffect
                    className=""
                    speed={-10}
                    rotateFrom={30}
                    rotateTo={-15}
                  >
                    <img className="w-100 opacity-10" src={thumb} />
                  </ScrollEffect>
                </div>
              </div>
            </div>

            <div class="md:col-span-7 col-span-12 z-10">
              <Reveal reveal={"new"}>
                <p class="mb-8 relative z-10">
                  Each and every customer will have an instant reaction to your
                  business, our job is to utilise that snap judgement to speak
                  to your target audience. Branding is what people think about
                  you, lets convert those thoughts to clients, customers, sales
                  & success! We use a multitude of mediums to achieve the
                  correct perception of your brand. Without a strategic set of
                  brand guidelines for your business, customers will make up
                  their own minds about you and your company.{" "}
                </p>
                <p class="mb-8 relative z-10">
                  Get ahead of public perception and present yourself in a way
                  you are happy with. Without branding, your company may be
                  perceived negatively, or even worse completely forgotten.
                  Failing to be memorable is the worst thing a company can be,
                  it is a missed opportunity for any potential customers. There
                  are no returning customers to forgettable brands. Be a
                  memorable company, make your brand stand out.{" "}
                </p>
              </Reveal>
            </div>
          </div>
        </section>
      </ChangeToPrimary>
    </div>
  );
}

export default GraphicsIntro;
