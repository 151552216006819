import React from "react";
import { Reveal } from "../Hooks/Reveal";
import ScrollEffect from "../Hooks/ScrollEffect";
import { default as coin } from "./images/assets/coin-red.svg";
import { Link } from "react-router-dom";
import { default as lineBottom } from "./images/assets/lineBottom.svg";
import "./css/assets.css";

function RollingBanner() {
  const coinStyles = {
    bottom: "15%",
    left: "-30px",
    width: "20%",
    maxWidth: "150px",
    minWidth: "80px",
    top: "-15%",
    position: "absolute",
  };

  return (
    <div className="bg-white text-dark relative">
      <section className="container mx-auto px-4 py-8  md:py-12 lg:py-12">
        <div className="grid grid-cols-12 gap-8">
          <div
            class="
        md:col-start-2 md:col-span-10
        lg:col-start-2 lg:col-span-10
        xl:col-start-3 xl:col-span-8
         sm:col-span-12
         col-span-12
          px-8 py-8 rounded-xl text-center bg-white relative"
          >
            <div style={coinStyles}>
              <ScrollEffect rotateFrom={-40} rotateTo={10} speed={2}>
                <img src={coin} />
              </ScrollEffect>
            </div>
            <Reveal reveal={false}>
              <h4 class="text-2xl mb-8 lg:text-3xl leading-none font-heading">
                Get In Touch Today
              </h4>
              <p>We can make your business grow</p>
            </Reveal>
            <Link to="/Contact" className="">
              <Reveal reveal={false}>
                <button class="font-bold bg-primary text-light px-8 mt-5 group inline-block py-2 text-base leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out hover:bg-primaryDark hover:text-light">
                  Start project
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </Reveal>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RollingBanner;
