import React, { useState } from "react";
import CarouselCard from "./CarouselCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const GetCarousel = ({ items }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 3,
      //  slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 2,
      // slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
      // slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 75,
    },
  };

  const svgStyleTop = {
    //position: "absolute",
    color: "white",
    width: "100%",
    top: "-1px",
  };

  const carouselBgWrapper = {
    color: "white",
    width: "100%",
    height: "100%",
    top: "-1px",
  };

  const carouselBgblock = {
    width: "100%",
    height: "50%",
  };

  // const [currentPage, setCurrentPage] = useState(1);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = items.data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="flex justify-end z-100 bg-light pt-2 pb-12">
      <div className="w-full relative right-0">
        <Carousel
          arrows={true}
          autoPlaySpeed={3000}
          swipeable={true}
          minimumTouchDrag={80}
          autoPlay={false}
          //transitionDuration={1000}
          infinite
          partialVisible={true}
          responsive={responsive}
        >
          {items.data.map((post) => (
            <div key={post.id}>
              <CarouselCard post={post} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default GetCarousel;
