import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as websiteSpeedImage } from "../../images/assets/website-speed.jpg";

function SpeedWebsite(props) {
  const iconStyles = {
    right: "2%",
    width: "15%",
    maxWidth: "250px",
    minWidth: "80px",
    bottom: "0%",
    position: "absolute",
    opacity: 1,
  };

  return (
    <div>
      <div className="relative">
        <section className="container mx-auto">
          <div style={iconStyles}></div>
          <div className="">
            <section className="container relative mx-auto px-4 py-18 z-10">
              <div className="grid grid-cols-12 gap-8">
                <div className="lg:col-span-6 lg:col-span-5 col-span-12 mx-auto relative">
                  <ScrollEffect speed={8}>
                    <img
                      className="w-100 p-2 mx-auto"
                      src={websiteSpeedImage}
                      style={{ minHeight: "300px" }}
                    />
                  </ScrollEffect>
                </div>
                <div className="lg:col-span-6 col-span-12 md:py-12 lg:py-24 relative">
                  <ScrollEffect speed={-3}>
                    <h4 class="text-sm mb-2 font-thin italic text-primary">
                      Faster sites rank higher!
                    </h4>
                    <h3 class="text-4xl mb-5 text-dark">
                      Beautiful websites built for{" "}
                      <span class="underlineSvg">speed!</span>
                    </h3>
                    <p class="mb-5">
                      Speed is important for google listings. Google is less
                      likely to show your website in search results if it does
                      not meet adequate page speeds. The faster your site the
                      more likely google is to display your website high in its
                      search result.
                    </p>
                  </ScrollEffect>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SpeedWebsite;
