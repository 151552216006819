import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/Pages/About";
import Home from "./components/views/Home";
import Posts from "./components/Pages/Posts";
import { Post } from "./components/views/Post";
import { Cases } from "./components/views/Cases";
import { Case } from "./components/views/Case";
import Services from "./components/Pages/Services";
import Contact from "./components/Pages/Contact";
import ScrollToTop from "./Hooks/ScrollToTop";
/*custom pages */
import Website from "./components/Pages/Website";
import Branding from "./components/Pages/Branding";
import GraphicDesign from "./components/Pages/GraphicDesign";
import Ecommerce from "./components/Pages/Ecommerce";
import SEO from "./components/Pages/SEO";
import PerformanceMarketing from "./components/Pages/PerformanceMarketing";
import CasesSwitch from "./components/views/CasesSwitch";
import TopBanner404 from "./components/TopBanner404";
function App() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* <Header /> */}
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/posts/page/:pagenumber/:catId" element={<Posts />} />
          <Route path="/posts/:slug" element={<Post />} />

          <Route path="/case-studies" element={<Cases />} />

          <Route path="/case-studies/page/:pagenumber" element={<Cases />} />
          <Route path="/case-studies/:slug" element={<Case />} />

          <Route path="/services/website-design" element={<Website />} />
          <Route
            path="/services/content-marketing-graphic-design"
            element={<GraphicDesign />}
          />
          <Route path="/services/branding-marketing" element={<Branding />} />
          <Route path="/services/ecommerce-website" element={<Ecommerce />} />
          <Route
            path="/services/performance-marketing-optimisation"
            element={<PerformanceMarketing />}
          />
          <Route
            path="/services/SEO-search-engine-optimisation"
            element={<SEO />}
          />
          <Route path="/contact" element={<Contact />} />
          {/* Redirects */}
          <Route
            path="/services/branding"
            element={<Navigate replace to="/services/branding-marketing" />}
          />

          <Route
            path="/services/SEO-website"
            element={
              <Navigate replace to="/services/SEO-search-engine-optimisation" />
            }
          />
          <Route path="*" element={<TopBanner404 />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
