import React from "react";
import { Link } from "react-router-dom";
import { ChangeBGColour } from "../../../Hooks/ChangeBGColour";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as cat } from "../../images/assets/primary/thumbs.svg";
import { default as creatingBranding } from "../../images/assets/creatingBranding.svg";
import { default as brandingLogo } from "../../images/assets/brandingLogo.svg";
import { useSpring, animated, config, easings } from "react-spring";
import { RevealText } from "../../../Hooks/RevealText";

function ServicesBranding() {
  const smilingmanStyles = {
    bottom: "20px",
    right: "10px",
    width: "50%",
    position: "absolute",
  };

  const iconStyles = {
    left: "40%",
    width: "25%",
    maxWidth: "250px",
    minWidth: "180px",
    top: "0%",
    position: "absolute",
    opacity: "0.05",
  };

  const creatingBrandingStyles = {
    opacity: 1,
  };

  const { background, rotateZ } = useSpring({
    from: {
      background: "#fff",
      rotateZ: -18,
    },
    to: {
      background: "#fff",
      rotateZ: 18,
    },
    config: {
      duration: 1200,
      easing: easings.easeInOutQuart,
    },
    loop: { reverse: true },
  });

  return (
    <div>
      <div className="relative text-dark">
        <div className="relative">
          <div style={iconStyles}>
            <ScrollEffect rotateFrom={-40} rotateTo={10} speed={-8}>
              <img src={cat} />
            </ScrollEffect>
          </div>
          <section className="container mx-auto px-4 py-12 md:py-8 lg:py-12  min-h-screen content-center">
            <div className="grid grid-cols-12 gap-8">
              <div className="lg:col-span-5 md:col-span-9 lg:order-last md:col-start-2 col-span-12 relative lg:mt-12">
                <div style={creatingBrandingStyles}>
                  <ScrollEffect speed={8}>
                    <div style={smilingmanStyles}>
                      <animated.div
                        style={{ background, borderRadius: 25, rotateZ }}
                      >
                        <img src={brandingLogo} />
                      </animated.div>
                    </div>

                    <img className="w-100 p-2 mx-auto" src={creatingBranding} />
                  </ScrollEffect>
                </div>
              </div>
              <div class="lg:col-span-7 md:col-span-10 md:col-start-2 col-span-12 md:mb-12 lg:mt-16 md:px-12">
                <RevealText>
                  <h3 class="leading-tight md:text-2xl xl:text-3xl font-heading relative z-10 mb-8">
                    Creating Brands That Last A Lifetime
                  </h3>
                </RevealText>
                <Reveal>
                  <p class="mb-8 relative z-10">
                    Each and every customer will have an instant reaction to
                    your business, our job is to utilise that snap judgement to
                    speak to your target audience. Branding is what people think
                    about you, lets convert those thoughts to clients,
                    customers, sales & success! We use a multitude of mediums to
                    achieve the correct perception of your brand. Without a
                    strategic set of brand guidelines for your business,
                    customers will make up their own minds about you and your
                    company.{" "}
                  </p>
                </Reveal>
                <Reveal>
                  <p class="mb-8 relative z-10">
                    Get ahead of public perception and present yourself in a way
                    you are happy with. Without branding, your company may be
                    perceived negatively, or even worse completely forgotten.
                    Failing to be memorable is the worst thing a company can be,
                    it is a missed opportunity for any potential customers.
                    There are no returning customers to forgettable brands. Be a
                    memorable company, make your brand stand out.{" "}
                  </p>
                </Reveal>

                <Link
                  alt="View branding page"
                  to={`/services/branding-marketing`}
                  className=""
                >
                  <button
                    alt="Plain Design sudio "
                    class="my-0 mx-0 group inline-block text-dark leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  >
                    Visit branding page
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 w-10 ml-2 group-hover:translate-x-4 transition transition-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ServicesBranding;
