import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import ScrollEffect from "../../../Hooks/ScrollEffect";
import { RevealImage } from "../../../Hooks/RevealImage";
import { Reveal } from "../../../Hooks/Reveal";

function PostCard(props) {
  const { title, excerpt, featured_media, id, date, slug } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString("default", { day: "2-digit" });
  const month = newdate.toLocaleString("default", { month: "short" });
  const year = newdate.toLocaleString("default", { year: "numeric" });
  const formatteddate = day + ", " + month + " " + year;

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <h2></h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
    );
  }

  //Get current post

  if (image.data) {
    //FeaturedImage = image.data.media_details.sizes.full.source_url;
    FeaturedImage = (
      <div
        style={{
          minHeight: "400px",
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url("${image.data.media_details.sizes.full.source_url}")`,
        }}
        class="rounded-lg drop-shadow-md overflow-hidden inset-0 h-full w-full object-cover object-center FeaturedImage"
      ></div>
    );
  }

  return (
    <div>
      <div v-for="card in cards" class="flex flex-col md:flex-row-100">
        <ScrollEffect class="grid grid-cols-12" YFrom={0} YTo={-15}>
          <Reveal>
            <div class="col-span-12">
              <Link to={`/case-studies/${slug}`}></Link>
            </div>

            <div class="col-span-12 m-2">
              <Link
                class="w-full p-4 flex flex-col justify-between text-dark"
                to={`/case-studies/${slug}`}
              >
                <ScrollEffect class="grid grid-cols-12" YFrom={0} YTo={-12}>
                  {FeaturedImage}
                </ScrollEffect>
                <div class="mx-4">
                  <h4
                    class="text-lg leading-tight mb-2"
                    dangerouslySetInnerHTML={{ __html: title.rendered }}
                  ></h4>
                  <div
                    class="leading-tight"
                    dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
                  ></div>

                  <button class="text-left group inline-block bg-none py-2 text-dark font-medium text-base leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                    Find out more
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="inline-block h-8 w-10 ml-2 group-hover:translate-x-5 transition transition-500"
                      fill="primary"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </div>
              </Link>
            </div>
          </Reveal>
        </ScrollEffect>
      </div>
    </div>
  );
}

export default PostCard;
