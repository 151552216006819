import React from "react";
import { Link } from "react-router-dom";
import { useAxiosGet } from "../../../Hooks/httpRequest";
import { useParallax } from "react-scroll-parallax";
import "../../css/assets.css";
import "../../css/bgImages.css";
import { RevealImage } from "../../../Hooks/RevealImage";
import { Reveal } from "../../../Hooks/Reveal";

function CarouselCard(props) {
  const { title, featured_media, id, slug } = props.post;

  const url = `${process.env.REACT_APP_MY_WP_API_KEY}wp-json/wp/v2/media/${featured_media}`;

  /*
  const newdate = new Date(Date.parse(date));
  const day = newdate.toLocaleString('default', { day: '2-digit' });
  const month = newdate.toLocaleString('default', { month: 'short' });
  const year = newdate.toLocaleString('default', { year: 'numeric' });
  const formatteddate = day + ', ' + month + ' ' + year;
  */

  const image = useAxiosGet(url);

  let FeaturedImage = null;

  if (image.error) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <h2>Opps, Looks like there is an error</h2>
      </div>
    );
  }

  if (image.loading) {
    FeaturedImage = (
      <div class="flex items-center justify-center space-x-2">
        <div
          class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
          role="status"
        >
          <span class="visually-hidden">L</span>
        </div>
      </div>
    );
  }

  //Get current post

  if (image.data) {
    FeaturedImage = image.data.media_details.sizes.full.source_url;
  }

  return (
    <div
      v-for="card in cards mb-8"
      className="relative flex flex-wrap flex-col overflow-hidden
    md:mx-4
    sm:m-1
    relative
    m-2 bg-light rounded-xl shadow-md w-100 transform transition duration-500 hover:bg-primary hover:text-white"
    >
      <div className="w-full h-full relative">
        <div
          className="w-full h-full top-0 backgroundImage absolute"
          style={{
            backgroundImage: `url(${FeaturedImage})`,
          }}
        ></div>
        <div
          className="w-100 top-0 w-full h-full top-0 backgroundImage absolute bg-gradient-to-t from-dark to-transparent py-3 px-4"
          style={{ mixBlendMode: "darken" }}
        ></div>
        <div
          className="z-8 relative align-bottom grid gap-1 content-end"
          style={{ height: "450px" }}
        >
          <div className="w-100 top-0 py-3 px-4">
            <h4 class="text-lg text-white outLineText leading-tight align-bottom mb-4">
              {title.rendered}
            </h4>

            <Link to={`/case-studies/${slug}`} className="">
              <button class="text-light text-xs my-0 mx-0 p-0 group inline-block leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                View More
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline-block h-8 w-10 ml-2 group-hover:translate-x-8 transition transition-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselCard;
