import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParallax } from "react-scroll-parallax";
import { useSpring, animated, config } from "react-spring";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// UPDATE this path to your copy of the hook!
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
// import usePrefersReducedMotion from '@hooks/use-prefers-reduced-motion.hook';
// export const Reveal = (props) => {
//   // const [refHidden, setHidden] = React.useState(false)
//   // const cbRef = useIntersectionObserver({ threshold: 1 }, (entries) => {
//   //   entries.forEach((entry) => {
//   //     setHidden(!entry.isIntersect)
//   //   })
//   // })

//   // useEffect(() => {
//   //   window.addEventListener("scroll", scrollHandler, { passive: true });
//   //   return () => window.removeEventListener("scroll", scrollHandler);
//   // }, [revealed]);

//   // const scrollHandler = () => {
//   //   if (
//   //     window.pageYOffset + (window.innerHeight / 8) * 6 >=
//   //     hiddenRef.current.offsetTop
//   //   ) {
//   //     setReveal(true);
//   //   } else {
//   //     setReveal(false);
//   //   }
//   // };

//   // const { revealStyle } = useSpring({
//   //   revealStyle: revealed ? 1 : 0,
//   //   from: {
//   //     revealStyle: 0,
//   //   },
//   //   config: config.molasses,
//   // });

//   const [hidden, setHidden] = React.useState(false);
//   const cbRef = useInView({ threshold: 1 }, (entries) => {
//     entries.forEach((entry) => {
//       setHidden(!entry.isIntersect);
//     });
//   });

//   //return revealStyle;
//   return (
//     <div>

//       {/* <animated.div
//         ref={hiddenRef}
//         style={{
//           opacity: revealStyle.to({
//             range: [0, 1],
//             output: ["0", "1"],
//           }),
//           translateY: revealStyle.to({
//             range: [0, 1],
//             output: ["80px", "0px"],
//           }),
//         }}
//       >
//         {props.children}
//       </animated.div> */}
//     </div>
//   );
// };

export const Reveal = ({ children }) => {
  //const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.5 });
  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (inView) {
      console.log(inView);
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [inView]);

  return (
    <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.8, delay: 0 }}
      >
        {children}
      </motion.div>
    </div>
  );
};
