import React from "react";
import { Link } from "react-router-dom";
import { Reveal } from "../../../Hooks/Reveal";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as computer } from "../../images/assets/computer.svg";
import { default as webPage } from "../../images/assets/website-page.svg";
import { default as cursor } from "../../images/assets/cursor.svg";
import { default as boxSize } from "../../images/assets/box-size.svg";
import { default as websiteSpeed } from "../../images/assets/website-speed.png";
import { default as websiteBg } from "../../images/assets/website-bg.png";
//website-bg
import { useSpring, animated, config, easings } from "react-spring";
import AnimatedAsset from "../../../Hooks/AnimatedAsset";
import { RevealText } from "../../../Hooks/RevealText";

function ServicesWebDesign() {
  const iconStyles = {
    left: "0%",
    width: "25%",
    maxWidth: "350px",
    minWidth: "280px",
    top: "0%",
    opacity: "0.05",
    position: "absolute",
  };

  const cursorStyle = {
    bottom: "10%",
    left: "40px",
    width: "30px",
    position: "absolute",
    zIndex: 2,
  };

  const webPageStyle = {
    bottom: "30%",
    width: "100%",
    minWidth: "220px",
    height: "auto",
    left: "-70%",
    position: "absolute",
  };

  const webPageIMGStyle = {
    width: "100%",
    left: "0px",
    maxWidth: "400px",
    zIndex: 1,
  };

  const googleStyle = {
    top: "0px",
    right: "0px",
    width: "60%",
    minWidth: "220px",
    position: "absolute",
  };

  return (
    <div>
      <div className="relative bg-content text-lidarkght">
        <section className="container mx-auto px-4 py-16 relative z-10 md:py-12 lg:py-20">
          <div className="grid grid-cols-12 gap-8 z-10">
            <div className="lg:col-span-5 md:col-span-9 md:col-start-2 col-span-12 lg:mt-12">
              <div className="relative">
                <div style={cursorStyle}>
                  <AnimatedAsset
                    duration={25000}
                    rotate={0}
                    minusrotate={0}
                    posx={100}
                    minusx={100}
                    posy={-50}
                    minusy={50}
                  >
                    <img src={cursor} />
                  </AnimatedAsset>
                </div>
                <ScrollEffect speed={8}>
                  <img className="w-100 p-2 mx-auto right-0" src={websiteBg} />
                </ScrollEffect>
                <div style={googleStyle}>
                  <ScrollEffect speed={8}>
                    <img
                      className="w-100 p-2 mx-auto right-0"
                      src={websiteSpeed}
                    />
                  </ScrollEffect>

                  <div className="absolute" style={{ ...webPageStyle }}>
                    <AnimatedAsset
                      duration={25000}
                      rotate={0}
                      minusrotate={0}
                      posx={20}
                      minusx={20}
                      posy={10}
                      minusy={10}
                    >
                      <img
                        className="w-100 mx-auto left-0  absolute"
                        src={webPage}
                      />
                    </AnimatedAsset>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-7 md:col-span-12 md:col-start-2 col-span-12 md:px-12">
              <RevealText>
                <h3 class="leading-tight md:text-2xl xl:text-3xl font-heading relative z-10 mb-8">
                  Bespoke Website Design For Customer Conversion
                </h3>
              </RevealText>
              <Reveal>
                <p>
                  Here at Plain Design Studio, we are passionate about website
                  design and we strive to create the best websites possible. The
                  first objective is to understand your business and clarify
                  your goals, which is essential to creating a long-lasting
                  website. Grow your business with a bespoke website.{" "}
                </p>
              </Reveal>
              <Reveal>
                <p class="mb-8 relative z-10">
                  We will give you a solid idea of what a website can do for
                  your company. They are an essential tool for most businesses.
                  Defining the objective of your website is essential to its
                  success. Having clear intentions is the perfect first step in
                  building a long-lasting site, which performs to its maximum
                  potential. Knowing your customer base is essential to
                  developing an online presence. Before we begin your website
                  design, you should have clear branding and a prominent mission
                  statement.
                </p>
              </Reveal>
              <Link
                alt="View webdesign and development page"
                to={`/services/website-design`}
                className=""
              >
                <button class="my-0 mx-0 group inline-block text-dark leading-tight focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                  Visit website page
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline-block h-8 w-10 ml-2 group-hover:translate-x-4 transition transition-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </section>

        <div className="z-0" style={iconStyles}>
          <ScrollEffect rotateFrom={-40} rotateTo={8} speed={0}>
            <img src={computer} />
          </ScrollEffect>
        </div>
      </div>
    </div>
  );
}

export default ServicesWebDesign;
