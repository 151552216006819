import React from "react";
// import TopBanner from "../TopBanner";
import TopBanner from "../views/About/TopBanner";
import AboutIntro from "../views/About/AboutIntro";
import Values from "../views/About/Values";

function About() {
  return (
    <div>
      <TopBanner />
      {/* <TopBanner
        metaHeader="About Plain Design Studio - Headquarter in Melbourne"
        header="About us"
        metaDescription="Learn more about Plain Design Studio headerquarter in Melbourne, Victoria. We are website and digital marketing professionals"
      /> */}
      <AboutIntro />
      <Values />
    </div>
  );
}

export default About;
