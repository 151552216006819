import React from "react";
import { ScrollEffect } from "../../../Hooks/ScrollEffect";
import { default as rocket } from "../../images/assets/rocket.svg";
import { default as standoutimage } from "../../images/assets/primary/stand-out-image.svg";
import { default as coin } from "../../images/assets/coin-red.svg";

function ServiceWebsite(props) {
  const coinStyles = {
    bottom: "15%",
    left: "-30px",
    width: "20%",
    maxWidth: "150px",
    minWidth: "80px",
    top: "40%",
    position: "absolute",
  };

  const iconStyles = {
    right: "2%",
    maxWidth: "250px",
    minWidth: "80px",
    bottom: "0%",
    position: "absolute",
    opacity: 1,
  };

  return (
    <div>
      <div className="relative bg-light">
        <section className="container mx-auto">
          <div style={iconStyles}></div>
          <div className="">
            <section className="container relative mx-auto px-4 py-8 z-10 md:py-8 lg:py-8">
              <div className="grid grid-cols-12 gap-8">
                <div className="xl:col-span-4 lg:col-span-6 col-span-12 relative">
                  <div style={coinStyles}>
                    <ScrollEffect rotateFrom={-40} rotateTo={10} speed={2}>
                      <img src={coin} />
                    </ScrollEffect>
                  </div>
                  <ScrollEffect className="h-100" rotateFrom={2} rotateTo={2}>
                    <img
                      className="mx-auto"
                      src={standoutimage}
                      style={{
                        minHeight: "200px",
                        height: "100%",
                        width: "100%",
                        maxHeight: "500px",
                      }}
                    />
                  </ScrollEffect>
                </div>
                <div className="xl:col-span-8 lg:col-span-6 col-span-12 mx-auto py-8 z-10 py-0 lg:py-24">
                  <ScrollEffect speed={-3}>
                    <h4 class="text-sm mb-2 font-thin italic text-blue">
                      Graphic Design & Content Marketing
                    </h4>
                    <h4 class="text-3xl lg:text-3xl leading-tight font-heading relative z-10 mb-4">
                      Make sure your designs stand out!
                    </h4>
                    <p class="mb-8 relative z-10">
                      Standing out is the best way to get attention and form
                      meaningful connections with your audience. Graphic design
                      is the most efficient way of delivering a specific message
                      The tools used in graphic design allow an immediate
                      connection to your brand; it will instantly form an
                      emotional response with the viewer.
                    </p>
                  </ScrollEffect>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ServiceWebsite;
