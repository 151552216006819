import React, { useState, component } from "react";
import { Link } from "react-router-dom";

function PaginationNav(props) {
  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(event);
  }

  const currentPage = parseInt(props.currentPage);
  const currentCat = parseInt(props.currentCat);
  const totalItems = props.totalItems;
  const itemsPerPage = props.itemsPerPage;
  const pageNumbers = [];

  let prev = currentPage;
  let next = currentPage;
  let prevButton = null;
  let nextButton = null;

  prev--;
  next++;

  if (currentPage > 1) {
    prevButton = (
      <Link to={`/posts/page/${prev}/${currentCat}`}>
        <li className="page-item list-none">
          <button
            class="page-link relative py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-500 focus:shadow-none"
            onClick={() => {
              handleChange(prev);
            }}
          >
            Previous
          </button>
        </li>
      </Link>
    );
  }

  if (currentPage > 1) {
    prevButton = (
      <Link to={`/posts/page/${prev}/${currentCat}`}>
        <li className="page-item list-none">
          <button
            class="page-link relative py-1 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-dark focus:shadow-none hover:text-dark hover:bg-gray-200"
            onClick={() => {
              handleChange(prev);
            }}
          >
            Previous
          </button>
        </li>
      </Link>
    );
  }

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (pageNumbers.length > currentPage) {
    nextButton = (
      <Link to={`/posts/page/${next}/${currentCat}`}>
        <li className="page-item list-none">
          <a
            class="page-link relative py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-dark focus:shadow-none hover:text-dark hover:bg-gray-200"
            onClick={() => {
              handleChange(next);
            }}
          >
            Next
          </a>
        </li>
      </Link>
    );
  }

  return (
    <div className="flex justify-center">
      <nav aria-label="Page navigation mx-auto">
        <ul className="flex list-style-none items-center gap-2">
          {prevButton}
          {pageNumbers.map((number) => {
            if (currentPage === number) {
              return (
                <Link
                  className="active"
                  to={`/posts/page/${number}/${currentCat}`}
                >
                  <li key={number} className="page-item active list-none">
                    <a
                      className="pagination_button page-link relative block text-base py-1 px-3.5 mx-1 bg-primary text-white outline-none transition-all duration-300 rounded-full text-dark hover:text-primary hover:bg-transparent"
                      onClick={() => {
                        handleChange(number);
                      }}
                    >
                      {number}
                    </a>
                  </li>
                </Link>
              );
            } else {
              return (
                <Link
                  className="inactive"
                  to={`/posts/page/${number}/${currentCat}`}
                >
                  <li key={number} className="page-item list-none">
                    <a
                      className="pagination_button page-link relative block py-1 px-3.5 bg-light text-dark outline-none transition-all duration-300 rounded-full text-dark hover:text-primary hover:border-primary"
                      onClick={() => {
                        handleChange(number);
                      }}
                    >
                      {number}
                    </a>
                  </li>
                </Link>
              );
            }
          })}
          {nextButton}
        </ul>
      </nav>

      <div></div>
    </div>
  );
}

export default PaginationNav;
